/*!
 * Tipografia
 * Ipiranga Eventos
 * Desenvolvido por Fattoria em: 26/09/2023
 */

// Ipiranga Títulos
@font-face {
  font-family: 'ipiranga-titulos';
  src: url('../fonts/ipirangatitulos-thin.woff2') format('woff2'),
  url('../fonts/ipirangatitulos-thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'ipiranga-titulos';
  src: url('../fonts/ipirangatitulos-light.woff2') format('woff2'),
  url('../fonts/ipirangatitulos-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ipiranga-titulos';
  src: url('../fonts/ipirangatitulos-regular.woff2') format('woff2'),
  url('../fonts/ipirangatitulos-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ipiranga-titulos';
  src: url('../fonts/ipirangatitulos-medium.woff2') format('woff2'),
  url('../fonts/ipirangatitulos-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ipiranga-titulos';
  src: url('../fonts/ipirangatitulos-bold.woff2') format('woff2'),
  url('../fonts/ipirangatitulos-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ipiranga-titulos';
  src: url('../fonts/ipirangatitulos-extrabold.woff2') format('woff2'),
  url('../fonts/ipirangatitulos-extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'ipiranga-titulos';
  src: url('../fonts/ipirangatitulos-black.woff2') format('woff2'),
  url('../fonts/ipirangatitulos-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

// Ipiranga Textos
@font-face {
  font-family: 'ipiranga-textos';
  src: url('../fonts/ipirangatextos-thin.woff2') format('woff2'),
  url('../fonts/ipirangatextos-thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'ipiranga-textos';
  src: url('../fonts/ipirangatextos-light.woff2') format('woff2'),
  url('../fonts/ipirangatextos-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ipiranga-textos';
  src: url('../fonts/ipirangatextos-regular.woff2') format('woff2'),
  url('../fonts/ipirangatextos-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ipiranga-textos';
  src: url('../fonts/ipirangatextos-medium.woff2') format('woff2'),
  url('../fonts/ipirangatextos-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ipiranga-textos';
  src: url('../fonts/ipirangatextos-bold.woff2') format('woff2'),
  url('../fonts/ipirangatextos-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ipiranga-textos';
  src: url('../fonts/ipirangatextos-extrabold.woff2') format('woff2'),
  url('../fonts/ipirangatextos-extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'ipiranga-textos';
  src: url('../fonts/ipirangatextos-black.woff2') format('woff2'),
  url('src/fonts/ipirangatextos-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

//
// Icones
//

@font-face {
  font-family: 'icones-ipiranga-eventos';
  src: url('../fonts/icones-ipiranga-eventos.eot');
  src: url('../fonts/icones-ipiranga-eventos.eot?#iefix') format('embedded-opentype'),
       url('../fonts/icones-ipiranga-eventos.woff') format('woff'),
       url('../fonts/icones-ipiranga-eventos.ttf')  format('truetype'),
       url('../fonts/icones-ipiranga-eventos.svg#icones-ipiranga-eventos') format('svg');
  font-weight: normal;
}

.icones {
  font-family: 'icones-ipiranga-eventos' !important;
  font-size: inherit;
  font-style: normal !important;
  font-weight: normal !important;
  color: inherit;
  font-display: swap;
}

.icone-sair { &::before { content: '\e900'; } }
.icone-duvidas { &::before { content: '\e901'; } }
.icone-meus-dados { &::before { content: '\e902'; } }
.icone-usuario { &::before { content: '\e903'; } }
.icone-chevron-down { &::before { content: '\e904'; } }
.icone-localizacao { &::before { content: '\e905'; } }
.icone-chevron-left { &::before { content: '\e906'; } }
.icone-facebook { &::before { content: '\e907'; } }
.icone-instagram { &::before { content: '\e908'; } }
.icone-calendario { &::before { content: '\e909'; } }
.icone-seta-esquerda { &::before { content: '\e90a'; } }
.icone-recuperar-senha { &::before { content: '\e90b'; } }
.icone-primeiro-acesso { &::before { content: '\e90c'; } }
.icone-olho-fechado { &::before { content: '\e90d'; } }
.icone-fechar-circular { &::before { content: '\e90e'; } }
.icone-relogio { &::before { content: '\e90f'; } }
.icone-seta-faq-direita { &::before { content: '\e910'; } }
.icone-olho-aberto { &::before { content: '\e911'; } }
.icone-check { &::before { content: '\e912'; } }
.icone-times { &::before { content: '\e913'; } }
.icone-times-1 { &::before { content: '\e914'; } }
.icone-seta-direita-grande { &::before { content: '\e916'; } }
.icone-seta-esquerda-grande { &::before { content: '\e918'; } }
.icone-chevron-right { &::before { content: '\e919'; } }
.icone-check-circle:before { content: '\e914'; }
.icone-check-circle-filled:before { content: '\e915'; }
.icone-check-small { &::before { content: '\e917'; } }
.icone-inscricao-evento { &::before { content: '\e91a'; } }
.icone-sobre-evento { &::before { content: '\e91b'; } }
.icone-outros-evento { &::before { content: '\e91c'; } }
.icone-novidades-evento { &::before { content: '\e91d'; } }
.icone-duvidas-evento { &::before { content: '\e91e'; } }
.icone-localizacao-evento { &::before { content: '\e91f'; } }
.icone-parceiros-evento { &::before { content: '\e920'; } }
.icone-palestrantes-evento { &::before { content: '\e921'; } }
.icone-sino { &::before { content: '\e922'; } }
.icone-chevron-esquerda { &::before { content: '\e923'; } }
.icone-chevron-direita { &::before { content: '\e924'; } }
.icone-galeria-evento { &::before { content: '\e925'; } }
.icone-exterior { &::before { content: '\e926'; } }
.icone-roteiro { &::before { content: '\e927'; } }
.icone-anexar { &::before { content: '\e928'; } }
.icone-eventos-evento:before { content: "\e929"; }
.icone-mapa-evento:before { content: "\e92a"; }