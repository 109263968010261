/* ==========================================================================
   Footer
   ========================================================================== */

.footer {
   background-color: #F2F2F3;
   padding: 30px 0 24px;

   @media (min-width: 992px) {
      padding: 34px 0 26px;
   }

   &-logo {
      margin-bottom: 34px;
      order: 1;

      @media (min-width: 992px) {
         margin-bottom: 0;
      }
   }

   &-text {
      color:  #1D1E1E;
      font-size: 12px;
      margin-bottom: 0;
      text-align: center;
      order: 3;

      @media (min-width: 992px) {
         order: 2;
      }
   }

   &-link {
      color: #0045B5;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      text-decoration-line: underline;
      transition: all ease-in-out;
      margin-bottom: 25px;
      order: 2;

      @media (min-width: 992px) {
         margin-bottom: 0;
         order: 3;
      }

      &:hover {
         text-decoration: none;
         color: darken( #0045B5, 10%);
      }
   }

   &-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 992px) {
         flex-direction: row;
         justify-content: space-between;
         align-items: center;
      }

   }

   &-evento {
      padding: 30px 0 110px;

      @media (min-width: 992px) {
         padding: 34px 0 26px;
      }
   }
}