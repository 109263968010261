.breadcrumb-padrao {
    display: flex;
    align-items: center;
    gap: 21px;
    margin: 16px 0 24px;

    @media (min-width: 992px){
        margin: 24px 0 32px;
    }

    .breadcrumb_item {
        position: relative;       
        color: #76777A;       
        font-size: 12px;     
        line-height: normal;

        ::after {
            font-family: 'icones-ipiranga-eventos' !important;
            font-size: inherit;
            font-style: normal !important;
            font-weight: normal !important;
            color: inherit;
            font-display: swap;
            content: '\e919';
            position: absolute;
            right: -14px;
            top: 50%;
            transform: translateY(-50%);
            color: #7A8598;
            font-size: 10px;
        }

        .breadcrumb_link {
            color: #0045B5;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.breadcrumb-palestrantes {
    @media (max-width: 992px) {
        flex-wrap: wrap;
        column-gap: 21px;
        row-gap: 12px;

        .breadcrumb_item {
            text-wrap: nowrap;
        }
    }
}