// Css raiz do projeto

/*!
 * Ipiranga Eventos
 * Desenvolvido por Fattoria em: 26/09/2023
 */

// Config
@import "config/_variaveis.scss";

// Tipografia
@import "_tipografia.scss";

// Geral
@import "geral/_reset.scss";
@import "geral/_medias.scss";
@import "geral/_geral.scss";
@import "geral/_utilidades.scss";
@import "geral/_animations.scss";


// Componentes
@import "componentes/_btns.scss";
@import "componentes/_forms.scss";
@import "componentes/_header.scss";
@import "componentes/_footer.scss";
@import "componentes/_links.scss";
@import "componentes/_modais.scss";
@import "componentes/_alerts.scss";
@import "componentes/_titulos.scss";
@import "componentes/_cards.scss";
@import "componentes/_breadcrumb.scss";
@import "componentes/_menu.scss";
@import "componentes/_tags.scss";
