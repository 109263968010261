.voltar_link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 0.3s ease;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);

    @media(min-width: 992px) {
        width: fit-content;
        height: initial;
        justify-content: initial;
        border-radius: initial;
        background-color: transparent;
    }

    &:hover {
        opacity: 0.8;
    }

    &.branco {
        color: white;
    }

    .link_text {
        display: none;

        @media(min-width: 992px) {
            display: block;
            font-size: 16px;          
            line-height: 24px;
            margin-left: 10px;
        }
    }
}

.link-padrao {
    font-size: 14px;   
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    transition: 0.3s ease;

    @media(min-width: 992px) {
        font-size: 16px;
    }

    &:hover {
        text-decoration: underline;
    }

    &.amarelo {
        color: #FFD100;
    }
}