// Tamanhos
.fs-8{ font-size: $fs-8 !important; }
.fs-10{ font-size: $fs-10 !important; }
.fs-12{ font-size: $fs-12 !important; }
.fs-14{ font-size: $fs-14 !important; }
.fs-16{ font-size: $fs-16 !important; }
.fs-18{ font-size: $fs-18 !important; }
.fs-20{ font-size: $fs-20 !important; }
.fs-22{ font-size: $fs-22 !important; }
.fs-23{ font-size: 23px !important; }
.fs-24{ font-size: $fs-24 !important; }
.fs-26{ font-size: $fs-26 !important; }
.fs-28{ font-size: $fs-28 !important; }
.fs-30{ font-size: $fs-30 !important; }
.fs-32{ font-size: $fs-32 !important; }
.fs-34{ font-size: $fs-34 !important; }
.fs-38{ font-size: $fs-38 !important; }
.fs-40{ font-size: $fs-40 !important; }
.fs-42{ font-size: $fs-42 !important; }
.fs-44{ font-size: $fs-48 !important; }
.fs-52{ font-size: $fs-52 !important; }
.fs-56{ font-size: $fs-56 !important; }
.fs-60{ font-size: $fs-60 !important; }
.fs-62{ font-size: $fs-62 !important; }
.fs-70{ font-size: $fs-70 !important; }
.fs-80{ font-size: $fs-80 !important; }
.fs-16-18{ 
    font-size: $fs-16 !important; 

    @media (min-width:992px) {
        font-size: $fs-18 !important; 
    }
}

.mb-24-32{ 
    margin-bottom: 24px;

    @media (min-width:992px) {
        margin-bottom: 32px;
    }
}

.mb-0-80{ 
    margin-bottom: 0;

    @media (min-width:992px) {
        margin-bottom: 80px;
    }
}


.fw-400{ font-weight: 400 !important; }
.fw-500{ font-weight: 500 !important; }
.fw-700{ font-weight: 700 !important; }

.lh-24{ line-height: 24px !important; }

.c-azul{ color: $azul !important; }
.c-azul-escuro-100{ color: #001C48 !important; }
.c-azul-claro{ color: $azul-claro !important; }
.c-cinza{ color: #9E9E9E !important; }
.c-cinza-100{ color: #797E86 !important; }
.c-amarelo{ color: $amarelo !important; }
.c-preto {color: black !important;}
.c-branco {color: white !important;}
.c-laranja {color: #FF8B00 !important;}
.c-cinza-200 {color: #939598 !important;}
.c-verde-florecente {color: #98DB09 !important;}

.bgc-amarelo{ background-color: $amarelo !important; }
.bgc-amarelo-km-vatangens{ background-color: $amarelo-km-vantagens !important; }
.bgc-amarelo-escuro{ background-color: $amarelo-escuro !important; }
.bgc-laranja{ background-color: $laranja !important; }
.bgc-azul{ background-color: $azul !important; }
.bgc-azul-claro{ background-color: $azul-claro !important; }
.bgc-azul-claro-2{ background-color: $azul-claro-2 !important; }
.bgc-azul-medio{ background-color: $azul-medio !important; }
.bgc-azul-medio-2{ background-color: $azul-medio-2 !important; }
.bgc-azul-escuro{ background-color: $azul-escuro !important; }
.bgc-vermelho-ampm{ background-color: $vermelho-ampm !important; }
.bgc-branco {background-color: white !important;}
.c-vermelho { color: #FF6900 !important; }
.bgc-azul-mobile{
    @media (max-width: 991px){
        background-color: $azul !important;
    }
}

.link-azul{
    color: #0045B5;
    text-decoration: underline;
    transition: all ease 0.4s;

    &:hover{
        text-decoration: none;
        opacity: 0.7;
    }
}

.link-underline{
    color: black;
    text-decoration-line: underline;
    text-decoration-color: inherit !important;
}

.mb-32{margin-bottom: 32px !important;}

.font-italic { font-style: italic !important; }
.cursor-pointer { cursor: pointer !important; }