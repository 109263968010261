.tag  {
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 16px;
      border-radius: 6px;
           
      font-size: 14px;   
      line-height: 110%;

      &.small {
        padding: 6px 10px;
        font-size: 12px;
        font-weight: 500;
      }     
      
      &.rounded-lg {
        border-radius: 32px;
      }

      &.azul {
        color: white;
        background-color: #0045B5;
      }

      &.azul-indigo {
        background-color: #0068FF;
        color: white;
      }

      &.laranja {
        color: white;
        background-color: #FF8B00;
        
      }

      &.cinza {
        color: white;
        background-color: #9E9E9E;        
      }
}