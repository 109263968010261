.card-novidade{
    display: flex;
    align-items: center;
    width: 100%;
    text-decoration: none;
    cursor: pointer;

    @media (min-width: 992px){
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        padding: 15px;
        background: #FFF;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.08), 0px 2px 5px 0px rgba(0, 0, 0, 0.08), 0px 10px 10px 0px rgba(0, 0, 0, 0.07), 0px 6px 40px 0px rgba(0, 0, 0, 0.04), 0px 40px 16px 0px rgba(0, 0, 0, 0.01), 0px 62px 17px 0px rgba(0, 0, 0, 0.00);
        border-radius: 16px;
    }

    &:hover {
        .image_holder {
            .card-novidade_img {
                transform: scale(1.1);
            }
        }
    }

    &_header{
        display: none;

        @media (min-width: 992px){
            display: flex;
            align-items: center;
            margin-bottom: 14px;
        }       

        &_img{
            display: block;
            margin-right: 12px;
            width: 34px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            aspect-ratio: 1/1;
            border-radius: 50%;
        }

        &_evento{
            margin-bottom: 0;
            color: #58595B;
            font-size: 14px;
        }

        &_data{
            margin-bottom: 0;
            color: #AFB2B7;
            font-size: 10px;
        }
    }

    .image_holder {
        position: relative;
        overflow: hidden;
        display: block;
        width: 100px;
        height: 80px;
        border-radius: 6px;

        @media (min-width: 992px){
            aspect-ratio: 16/9;
            width: 100%;
            height: auto;
        }     
        
        .tag {
            display: none;
        }

        .card-novidade_img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            transition: 0.3s ease;
        }
    }    

    &_info{
        margin-left: 16px;
        flex: 1;

        @media (min-width: 992px){
            margin-top: 16px;
            margin-left: 0;
            width: 100%;
        }

        &_titulo{
            margin-bottom: 0;
            color: #18191B;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;

            @media (min-width: 992px){
                margin-bottom: 8px;
                font-weight: 700;
            }
        }

        &_texto{
            display: none;
            margin-bottom: 0;
            color: #797E86;
            font-size: 14px;

            @media (min-width: 992px){
                display: block;
            }
        }

        &_evento{
            display: flex;
            align-items: center;
            margin-top: 8px;
            font-family: 'ipiranga-titulos', sans-serif;
            line-height: 14px; 
            font-size: 12px;
            color: #58595B;
            margin-bottom: 0;

            @media (min-width: 992px){
                display: none;
            }         
        }

        .tag {
            display: none;
            margin-top: 8px;      
            
            @media (max-width: 992px){
                margin-top: 0;
                margin-bottom: 8px;
            }  
        }
    }

    &_btn{
        display: none;

        @media (min-width: 992px){
            display: inline-flex;
            margin-top: 24px;
            width: 100%;
        }
    }

    &.novo{

        .card-novidade_header{

            &_img{
                position: relative;               
            }            
        }

        .card-novidade_info {
            .tag {
                display: flex;

                @media(min-width: 992px){
                    display: none;
                }
            }
        }

        .image_holder {

            .tag {            
                position: absolute;
                right: 6px;
                bottom: 6px;
                z-index: 2;

                @media(min-width: 992px){
                    display: flex;
                }
            }           
        }

        .card-novidade_info_evento_novidade{
            display: flex;
            align-items: center;
        }

        .btn{
            background-color: #0068FF;
        }
    }

    &.vazio{

        .card-novidade_header{

            &_img{
                background-color: #D1D3D4;
            }

            &_info{
                flex: 1;
            }

            &_evento{
                display: block;
                width: 65%;
                height: 13px;
                // background: linear-gradient(252deg, #FDFDFD 15.55%, #F5F0F0 81.47%);
            }

            &_data{
                display: block;
                margin-top: 4px;
                width: 100%;
                height: 7px;
                // background: linear-gradient(252deg, #FDFDFD 15.55%, #F5F0F0 81.47%);
            }
        }

        .card-novidade_img,
        .card-novidade_info_titulo,
        .card-novidade_info_evento{
            position: relative;
            background: #FDFDFD;
            overflow: hidden;

            &::before{
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(252deg, #FDFDFD 15.55%, #F5F0F0 81.47%);
            }
        }

        .card-novidade_info_texto,
        .card-novidade_header_evento,
        .card-novidade_header_data{
            @media (min-width: 992px){
                position: relative;
                background: #FDFDFD;
                overflow: hidden;
    
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(252deg, #FDFDFD 15.55%, #F5F0F0 81.47%);
                }
            }
        }

        .card-novidade_info{

            &_titulo{
                display: block;
                width: 98%;
                height: 13px;
            }

            &_texto{
                display: block;
                width: 70%;
                height: 10px;
            }

            &_evento{
                display: block;
                width: 70%;
                height: 10px;
            }
        }

        .card-novidade_btn{
            background-color: #F4F4F5;
        }

        &.animated{
            
            .card-novidade_img,
            .card-novidade_info_titulo,
            .card-novidade_info_texto,
            .card-novidade_header_evento,
            .card-novidade_header_data{
                &::before{
                    animation: gradiente-animado 4s ease-in-out infinite reverse;
                }
            }
        }
    }

    &.video{

        .card-novidade_img{
           

            &::after{
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
                display: block;
                width: 30px;
                height: 30px;
                background-image: url('../img/icone-play.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                transform: translate(-50%, -50%);
            }
        }
    }
}

@keyframes gradiente-animado{
    0%{
        width: 100%;
    }
    50%{
        width: 190%;
    }
    100%{
        width: 100%;
    }
}