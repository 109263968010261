.modal-padrao {
    border: 0;

    .modal-dialog {
        .modal-content {

            .modal-body {
                position: relative;
                padding: 24px;

                @media(min-width: 992px) {
                    padding: 48px 64px 60px;
                }

                .close_btn {
                    position: absolute;
                    right: 16px;
                    top: 16px;
                    background-color: transparent;
                    outline: none;
                    border: 0;
                    padding: 0; 
                    
                    @media(min-width: 992px) {
                        right: 30px;
                        top: 30px;
                    }
                }

                .title {
                    color: #0045B5;
                    font-family: 'ipiranga-titulos', sans-serif;
                    font-size: 32px;              
                    line-height: 38px;
                    margin-bottom: 24px;
                }

                .content_block {
                    margin-bottom: 32px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .intro_wrapper {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        margin-bottom: 13px;
                    }

                    .subtitle {
                        color: #000;
                        font-family: 'ipiranga-titulos', sans-serif;
                        font-size: 18px;                       
                        font-weight: 700;
                        line-height: normal;
                        margin-bottom: 0;
                    }

                    .text {
                        color: #303236;                  
                        font-size: 16px;                   
                        line-height: normal;
                        margin-bottom: 0;

                        & + .text {
                            margin-top: 16px;
                        }
                    }
                }

                .link {
                    color: #0045B5;
                    font-family: 'ipiranga-titulos', sans-serif;
                    font-size: 18px;               
                    line-height: normal;
                    text-decoration-line: underline;
                    transition: 0.3s ease;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }       
        }
    }
}

.modal-large {
    .modal-dialog {
        max-width: 794px;
    }
}

.modal-camisa {
    color: black;

    .modal-dialog {     

        @media(min-width: 576px) {
            max-width: 367px;
        }

        @media(min-width: 992px) {
            max-width: 1062px;
        }

        .modal-content {
            position: relative;
            padding: 24px;
            border-radius: 20px;
            border: none;

            @media(min-width: 992px) {           
                padding: 48px;
            }

            .fechar {
                display: none;
                position: absolute;
                right: 24px;
                top: 24px;   
                background-color: transparent;
                outline: none;
                border: 0;  
                
                @media(min-width: 992px) {
                display: block;
                }
            }

            .modal-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;         

                @media(min-width: 992px) {
                    flex-direction: row;          
                }

                .img_holder {
                    width: 100%;
                    max-width: 512px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 20px;
                    margin-bottom: 16px;

                    @media(min-width: 992px) {
                        margin-bottom: 0;
                        margin-right: 66px;
                    }

                    &::after {
                        content: '';
                        display: block;
                        padding-bottom: 107%;
                    }           
                }

                .btns_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 16px;
                    margin-bottom: 24px;

                    .btn-handle-feminino-masculino {
                        font-family: 'Poppins', sans-serif;
                        padding: 4px 16.5px;          
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 150%;               
                        color: #FFFFFF;
                        background-color: #C2C2C2;
                        border-radius: 6px;
                        margin: 0 4px;
                        border: 0;
                        outline: none;

                        &.active {
                            background-color: #072869;
                        }
                    }
                }

                .table {
                    text-align: center;

                    thead {
                        th {
                            border: 0;
                            font-size: 14px;

                            @media(min-width: 992px) {
                            font-size: 16px;
                            }
                        }
                    }

                    tbody {
                        td {
                            font-size: 12px;
                            border-bottom: 1px solid #D9D9D9 !important;

                            @media(min-width: 992px) {
                            font-size: 16px;
                            }
                        }
                    }
                }

                .texto {
                    font-family: 'Poppins', sans-serif;       
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 150%;            

                    @media(min-width: 992px) {
                    font-size: 12px;
                    }
                }
            }
        }
    }
}

.modal-erro{
    overflow: hidden;

    .modal-dialog{
        display: flex;
        align-items: flex-end;
        min-height: 100dvh;
        margin: 0;
    }

    .modal-content{
        border-radius: 20px 20px 0px 0px;
    }

    .modal-body{
        position: relative;
        padding: 64px 24px 24px;

        &::before{
            content: '';
            position: absolute;
            top: 24px;
            left: 50%;
            display: block;
            width: 105px;
            height: 5px;
            background-color: #D9D9D9;
            transform: translateX(-50%);
            border-radius: 20px;
        }
    }

    .texto{
        margin-bottom: 0;

        & + .texto{
            margin-top: 16px;
        }

        & + .btn{
            margin-top: 32px;
        }
    }

    &.fade{
        .modal-dialog{
            transform: translate(0,50px);
        }
    }

    &.show{
        .modal-dialog{
            transform: translate(0,0);
        }
    }
}

.modal.modal-inscrever-evento {

    &.show {
        .modal-dialog {        

            @media(max-width: 991px) {
                transform: translate3d(0, 0, 0);        
            }        
        }
    }

    .modal-dialog {            

        @media(min-width: 992px) {
            max-width: 600px;
        }

        @media(max-width: 991px) {
            max-width: 100%;       
            min-height: 100%;
            margin: 0;
            align-items: flex-end;  
            transition: 0.4s ease; 
            transform: translate3d(0, 100%, 0);         
        }        
    }

    .modal-content {
        padding: 24px 32px 24px;
        border-radius: 20px 20px 0 0;
        background-color: white;
        
        @media(max-width: 991px) {
            background-image: none !important;
        }

        @media(min-width: 992px) {
            position: relative;
            padding: 32px 32px 40px;
            border-radius: 20px;
            background-color: #072869;
            background-position: right;
            background-repeat: no-repeat;
            background-size: cover;

        }       

        .modal-body {
            padding: 0;
        }

        .close_btn {
            display: none;

            @media(min-width: 992px) {
                position: absolute;
                right: -32px;
                top: -70px;
                font-size: 16px;
                display: flex;
                background-color: transparent;
                border: 0;
                outline: none;
                color: white;
                transition: 0.3s ease;

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .mobile_bar {
            margin: 0 auto 35px;
            width: 105px;
            height: 5px;
            border-radius: 50px;
            background-color: #D9D9D9;

            @media(min-width: 992px) {
                display: none;
            }
        }

        .title {
            color: #0045B5;
            font-family: 'ipiranga-titulos', sans-serif;
            font-size: 32px;          
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 8px;

            @media(min-width: 992px) {
                color: white;
                line-height: 110%;
                font-weight: 700;
            }
        }

        .text {
            color: #18191B;       
            font-size: 18px;     
            line-height: 120%;
            margin-bottom: 24px;

            @media(min-width: 992px) {
                color: white;
                margin-bottom: 16px;
                font-weight: 500;
                line-height: 140%;
            }
        }

        .infos_block {
            display: flex;
            align-items: center;
            gap: 14px;

            @media(min-width: 992px) {
                gap: 8px;
            }

            & + .infos_block {
                margin-top: 16px;
            }

            .icones {
                color: #0045B5;
                font-size: 18px;
                line-height: 1;

                @media(min-width: 992px) {
                    color: #FFD100;
                    font-size: 20px;
                }
            }

            .info {
                color: black;             
                font-size: 14px;           
                // line-height: 110%;
                margin-bottom: 0;

                @media(min-width: 992px) {
                    color: #FFF;                  
                }
            }
        }

        .btn {
            width: 100%;
            height: 56px;

            @media(min-width: 992px) {
                width: initial;
                height: 48px;
            }
        }

        .btn-transparent {        
            color: #94989E;       
            font-size: 16px;            
            font-weight: 700;
            line-height: normal;
            margin: 24px 0 8px;
        }

        .btn-amarelo {
            color: #18191B;

            @media(min-width: 992px) {
                font-size: 14px;
                margin-top: 16px;
            }
        }
    }

    .modal-links_list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 0;

        .modal_link {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #36434D;
            text-decoration: none;                

            .icones {
                font-size: 24px;
            }

            .link_text {
                font-family: 'ipiranga-titulos', sans-serif;
                font-size: 20px;              
                font-weight: 500;
                line-height: normal;
            }

            .label {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 4px 8px;
                border-radius: 22px;
                font-family: 'ipiranga-titulos', sans-serif;
                font-size: 12px;              
                font-weight: 700;
                line-height: normal;
                margin-left: 8px;

                &.azul {
                    background-color: #0068FF;
                    color: white;
                }
            }
        }
    }

    &.v-2{

        .modal-dialog {        
            @media(min-width: 992px){
                max-width: 737px;      
            }
        }
        .modal-content{
            @media(min-width: 992px) {
                padding: 0;
                background-color: transparent;
            }

            .modal-body{
                @media(min-width: 992px) {
                    .row{
                         min-height: 352px;
                         margin: 0;
                    }      

                    .box{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        padding: 0 24px 0 32px;
                        background-color: white;
                        @media (min-width: 992px) {
                            border-radius: 20px 0 0 20px;
                        }
                    } 

                    .modal-image{
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        @media (min-width: 992px) {
                            border-radius: 0 20px 20px 0;
                        }
                    }

                    .btn-amarelo{
                        margin-top: 32px;
                        width: 100%;
                    }
                   
                }
            }
            .info{
                color: #18191B;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%; 
            }

             .infos_block {
                & + .infos_block {
                    margin-top: 24px;
                }
             }
        }
    }

    &--v2 {
        .modal-dialog {
            .modal-content {               

                @media(min-width: 992px){                   
                    background-color: #0045B5;
                }

                .modal-body {
                    position: relative;

                    .title {
                        @media(min-width: 992px){
                            color: #FFD100;
                        }                       
                    }

                    .flutuante {
                        display: none;
                        position: absolute;
                        right: -32px;
                        bottom: -40px;

                        @media(min-width: 992px){
                         display: block;
                        }   
                    }

                    &_content {
                        @media(min-width: 992px){
                            position: relative;
                            z-index: 3;
                            max-width: 323px;
                        }
                    }
                }
            }
        }
    }
}

.modal {
    &.modal-completar-dados {

        &.show {
            .modal-dialog {        
    
                @media(max-width: 991px) {
                    transform: translate3d(0, 0, 0);        
                }        
            }
        }
    
        .modal-dialog {            
    
            @media(min-width: 992px) {
                max-width: 359px;
            }
    
            @media(max-width: 991px) {
                max-width: 100%;       
                min-height: 100%;
                margin: 0;
                align-items: flex-end;  
                transition: 0.4s ease; 
                transform: translate3d(0, 100%, 0);         
            }        
        }
    
        .modal-content {
            padding: 47px 32px 24px;
            border-radius: 20px 20px 0 0;
    
            @media(min-width: 992px) {
                position: relative;
                padding: 22px 24px 32px;
                border-radius: 20px;               
            }       
    
            .modal-body {
                padding: 0;
            }
    
            .close_btn {
                display: none;
    
                @media(min-width: 992px) {
                    position: absolute;
                    right: -32px;
                    top: -52px;
                    font-size: 16px;
                    display: flex;
                    background-color: transparent;
                    border: 0;
                    outline: none;
                    color: white;
                    transition: 0.3s ease;
    
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
    
            .mobile_bar {
                margin: 0 auto 35px;
                width: 105px;
                height: 5px;
                border-radius: 50px;
                background-color: #D9D9D9;
    
                @media(min-width: 992px) {
                    display: none;
                }
            }
    
            .title {                         
                color: #0045B5;
                font-family: 'ipiranga-titulos', sans-serif;
                font-size: 32px;          
                font-weight: 500;
                line-height: 120%;
                margin-bottom: 18px;
                text-align: center;
    
                @media(min-width: 992px) {    
                    font-size: 24px;              
                    line-height: 140%;    
                    margin-bottom: 8px;           
                    text-align: left;   
                }

                .emoji {
                    display: inline-block;
                    margin-bottom: 2px;
                }
            }
    
            .text {
                color: #18191B;       
                font-size: 16px;   
                line-height: 120%;
                margin-bottom: 18px;
    
                @media(min-width: 992px) {                   
                    margin-bottom: 16px;                               
                }
            }
    
            .dados_block {
                display: flex;
                align-items: center;
                gap: 14px;
    
                @media(min-width: 992px) {
                    gap: 8px;
                }
    
                & + .dados_block {
                    margin-top: 10px;
                }               
    
                .dado {
                    color: black;             
                    font-size: 16px;                 
                    margin-bottom: 0;                                          
                }

                .label {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: 4px 6px;
                    border-radius: 4px;
                    background-color: #00ADFF;
                    color: white;
                    line-height: normal;
                    font-size: 11px;
                }
            }
    
            .btn {
                width: 100%;
                height: 56px;
                margin-top: 18px;
    
                @media(min-width: 992px) {                 
                    height: 48px;
                    margin-top: 24px;
                }
            }
    
            .btn-transparent {        
                color: #94989E;       
                font-size: 16px;            
                font-weight: 700;
                line-height: normal;
                margin: 24px 0 8px;
            }
    
            .btn-amarelo {
                color: #18191B;
    
                @media(min-width: 992px) {
                    font-size: 14px;
                    margin-top: 48px;
                }
            }
        }
    }
}

.modal-backdrop {   

    &.show {
        opacity: 0.8;
    }      
} 

.parceiro_modal {
    .modal-dialog {
        max-width: 640px;

        .modal-content {
            border-radius: 20px;

            .modal-body {
                position: relative;
                padding: 32px 32px 48px;
                color: black;

                .close_btn {
                    position: absolute;
                    right: 24px;
                    top: 24px;
                    background-color: transparent;
                    outline: none;
                    border: 0;
                }

                .modal_title {               
                    font-family: 'ipiranga-titulos', sans-serif;
                    font-size: 24px;             
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 16px;
                }

                .redes-sociais_wrapper {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    margin-bottom: 16px;

                    .rede-social_link {
                        text-decoration: none;
                        transition: 0.3s ease;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }

                .site_link {
                    display: inline-flex;
                    color: #0045B5;             
                    font-size: 16px;            
                    line-height: 17px;
                    text-decoration-line: underline;
                    margin-bottom: 24px;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .text_overflow {
                    height: 200px;
                    overflow: auto;
                    padding-right: 8px;

                    &::-webkit-scrollbar {
                        width: 8px;               
                      }
                      
                      &::-webkit-scrollbar-track {
                        background: rgb(155, 153, 153);  
                        border-radius: 8px;      
                      }
                      
                      &::-webkit-scrollbar-thumb {
                        background-color: rgb(66, 66, 66);   
                        border-radius: 20px;       
                      }
                }

                .text {                   
                    font-size: 16px;                 
                    line-height: normal;
                    margin-bottom: 0;

                    + .text {
                        margin-top: 16px;
                    }
                }

                .list {
                    margin-top: 16px;
                }
            }
        }
    }
}

.video_modal {

    .modal-dialog {
        max-width: 794px;
        padding: 0;
        
        @media(max-width: 991px){
            padding: 0 24px;
        }

        .modal-content {
            border-radius: 0;  
            background-color: transparent;
            border: 0;

            .modal-header {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0 0 8px;
                border: 0;

                .btn-close {
                    color: white;
                    background-color: transparent;
                    outline: none;
                    border: 0;
                    transition: 0.3s ease;
                    width: 24px;
                    height: 24px;
                    padding: 0;
                    margin-bottom: 0;
                    margin-right: 0;
                    opacity: 1;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            .modal-body {     
                display: flex;
                align-items: stretch; 
                max-height: 480px;         
                padding: 0;
                background-color: black;

                .video {
                    width: 100%;
                }

                .image {
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    max-height: 100%;
                    min-height: 190px;
                    object-fit: cover;

                    &.image-vertical{
                        object-fit: contain;

                        @media(max-width: 992px){
                           aspect-ratio:  1 / 1;
                        }
                    }
                }
            }    
            
            .modal-footer {
                padding: 15px 0 0;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                border: 0;
                gap: 24px;

                &:has(.galeria_card_legenda){
                    justify-content: space-between;
                }

                .galeria_card_legenda{
                    margin: 0;
                    color: white;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: normal;
                    flex: 1;
                }

                .btn{
                    margin: 0;
                    margin-left: auto;
                }

                .link-download{
                    color: #FFD100;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-decoration-line: underline;
                    // align-self: flex-end;
                }
            }
        }
    }
}

.modal-quiz{

    .modal-dialog{
        max-width: 100%;

        @media(min-width: 992px){
            width: 742px;
        }

        @media(max-width: 992px){
            margin: 0;
            padding-top: 100px;
        }
    }

    .modal-content {
        padding: 40px 32px 20px;
        border-radius: 16px 16px 0 0;   

        @media(min-width: 992px){
           padding: 32px 32px 40px; 
           border-radius: 16px;   
        }

        .modal-body {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;

            .line{
                width: 105px;
                height: 5px;
                border-radius: 50px;
                background: #D9D9D9;
                margin: 0 auto;
                margin-bottom: 35px;

                @media(min-width: 992px){
                    display: none;
                }
            }

            .img-header{
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                height: 220px;
                border-radius: 16px;
                margin-bottom: 24px;
            }

            .title{
                color: #0045B5;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%; 
                text-align: center;
                margin-bottom: 32px;
            }
            .form{
                margin-bottom: 24px;
            
                 input[type="radio"]{appearance: none;}

                .option{
                    width: 100%;
                    display: flex;
                    align-items: center;
        
                    .label{
                        width: 100%;
                        border-radius: 6px;
                        border: 1px solid #0045B5;
                        color:#0045B5;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: normal;
                        padding: 16px 24px;
                        cursor: pointer;
                    }

                    input:checked + .label{
                            color: white;
                            background-color: #0068FF;
                            border-color:  #0068FF;
                    }

                    &.error{
                        .label{
                            border-color:  #F04C25;
                            background-color: transparent;
                            color: #F04C25;
                            cursor:auto;
                        }
                    }

                    &.success{
        
                        .label{
                            border-color: #94DE00;
                            background-color: #94DE00;
                            color: white;
                            cursor: auto;
                        }
                    }
                }
            }
    

            .btn-azul{
                width: 100%;
                margin-top: 24px;
                opacity: 1;

                &:disabled{
                 opacity: 0.2;
                }
            }
            .status{
                color: #000;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%; 
                margin-top: 16px;
                margin-bottom: 0;
                margin-bottom: 20px;

                @media(min-width: 992px){
                    margin-bottom: 0;
                }
            }
        }

        .close_btn {
            display: none;

            @media(min-width: 992px) {
                position: absolute;
                right: -32px;
                top: -70px;
                font-size: 16px;
                display: flex;
                background-color: transparent;
                border: 0;
                outline: none;
                color: white;
                transition: 0.3s ease;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

.modal-enviar-pergunta {
    .modal-content {
        background-color: #FFF !important;
    }

    .close_btn {
        position: absolute;
        right: 0;
        top: 0;
        background-color: transparent;
        outline: none;
        border: 0;
        padding: 0; 
        display: block !important;
        
        @media(min-width: 992px) {
            right: 30px;
            top: 30px;
        }
    }

    .titulo-modal {
        color: #000;
        font-family: 'ipiranga-titulos', sans-serif;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 8px;
    }

    .texto-modal {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 16px;
    }

    .form-control {
        margin-bottom: 32px;
        height: 148px;

        @media (min-width: 992px) {
            width: 550px;
            height: 110px;
        }
    }

    .btn-azul {
        width: 100%;
        margin-bottom: 16px;

        @media (min-width: 992px) {
            min-width: 263px;
            margin-right: 24px;
            margin-bottom: 0;
        }
    }

    .texto-sucesso {
        color: #0045B5;
        text-align: center;
        font-family: 'ipiranga-titulos', sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 120%;
        margin: 132px 0 144px 0;
    }
}

.modal-vips-ouro {

    &.show {
        .modal-dialog {        

            @media(max-width: 991px) {
                transform: translate3d(0, 0, 0);        
            }        
        }
    }

    .modal-dialog {            

        @media(min-width: 992px) {
            max-width: 600px;
        }

        @media(max-width: 991px) {
            max-width: 100%;       
            min-height: 100%;
            margin: 0;
            align-items: flex-end;  
            transition: 0.4s ease; 
            transform: translate3d(0, 100%, 0);         
        }        
    }

    .modal-content {
        padding: 24px 32px 24px;
        border-radius: 20px 20px 0 0;
        background-color: white;
        position: relative;

        @media(min-width: 992px) {
            position: relative;
            padding: 32px 32px 40px 40px;
            border-radius: 20px;
        }       

        .modal-body {
            padding: 0;
        }

        .close_btn {
            display: none;

            @media(min-width: 992px) {
                position: absolute;
                right: -32px;
                top: -70px;
                font-size: 16px;
                display: flex;
                background-color: transparent;
                border: 0;
                outline: none;
                color: white;
                transition: 0.3s ease;

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .mobile_bar {
            margin: 0 auto 35px;
            width: 105px;
            height: 5px;
            border-radius: 50px;
            background-color: #D9D9D9;

            @media(min-width: 992px) {
                display: none;
            }
        }

        .title {
            color: #0045B5;
            font-family: 'ipiranga-titulos', sans-serif;
            font-size: 32px;          
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 8px;

            @media(min-width: 992px) {
                color: #0045B5;
                font-size: 32px;
                font-weight: 700;
                line-height: 110%;
            }
        }

        .text {
            color: #18191B;       
            font-size: 18px;     
            line-height: 120%;
            margin-bottom: 24px;

            @media(min-width: 992px) {
                color: #18191B;
                font-size: 18px;
                font-weight: 500;
                line-height: 140%;
                max-width: 343px;
            }
        }

        .infos_block {
            display: flex;
            align-items: center;
            gap: 14px;

            @media(min-width: 992px) {
                gap: 8px;
            }

            & + .infos_block {
                margin-top: 16px;
            }

            .icones {
                color: #0045B5;
                font-size: 18px;
                line-height: 1;

                @media(min-width: 992px) {
                    font-size: 20px;
                }
            }

            .info {
                color: #18191B;             
                font-size: 16px; 
                line-height: 110%;          
                margin-bottom: 0;
            }
        }

        .btn {
            width: 100%;
            height: 56px;

            @media(min-width: 992px) {
                width: 202px;
                height: 48px;
            }
        }

        .btn-amarelo {

            @media(min-width: 992px) {
                font-size: 14px;
                margin-top: 40px;
                color: #18191B;
            }
        }

        .img-modal-vips {
            position: absolute;
            bottom: 0;
            right: 0;
            display: none;

            @media (min-width: 992px) {
                display: block;
            }
        }
    }
}

.modal.modal-convencao {

    .modal-dialog {
        @media (min-width: 992px) {
            max-width: 737px;
        }
    }

    .modal-content {
        @media (min-width: 992px) {
            padding: 40px 24px 40px 40px;
            background-color: #0045B5;
        }
    }


    .modal-body {
        @media (min-width: 992px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 30px;
        }

        .content {
            @media (min-width: 992px) {
                max-width: 323px;
            }

        }

        .title {
            @media (min-width: 992px) {
                font-size: 24px;
            }
        }

        .text {
            @media (min-width: 992px) {
                font-size: 16px;
            }
        }

        .btn-amarelo {
            @media (min-width: 992px) {
                width: 100%;
                margin-top: 24px;
            }  
        }
    }

    .logo {
        display: none;

        @media (min-width: 992px) {
            display: block;
        }
    }
}

.modal-saibamais {

    .modal-body {

        .titulo {
            color: #000;
            text-align: center;
            font-family: "ipiranga-titulos";
            font-size: 22px;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 8px;
        }

        .texto {
            color: #000;
            text-align: center;
            font-family: "ipiranga-titulos";
            font-size: 14px;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 0;
        }

    }


}


.modal-agenda{

    .modal-dialog {
        @media (min-width: 992px) {
            max-width: 455px;
        }
    }

    .modal-content {
        height: 97vh;
        min-height: 600px;

        @media (min-width: 768px) { 
            height: auto;
            min-height: auto;
        }
    }

    .modal-body {
        padding: 32px 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: auto;

        @media (min-width: 992px) {
            padding: 40px 32px 32px;
            overflow: visible;
        }

        .close_btn {
            position: absolute;
            right: 16px;
            top: 16px;
            background-color: transparent;
            outline: none;
            border: 0;
            padding: 0; 
            color: #0045B5;
        }

        .title{
            color: #333;
            font-family: "ipiranga-titulos", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 4px;
        }
        .subtitle{
            color: #939598;
            font-family: "ipiranga-titulos", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; 
            text-transform: uppercase;
            margin-bottom: 16px;
        }
        .paragrafo{
            color: #303236;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; 
            margin-bottom: 24px;

            display: -webkit-box;
            -webkit-line-clamp: 3; 
            -webkit-box-orient: vertical;
            overflow: hidden;
            position: relative;
            line-height: 1.4; 

            &.expandido {
                display: block;
                -webkit-line-clamp: initial;
                overflow: visible;
                padding-right: 0;


                .btn-leia-mais{
                    position: static;
                    background: transparent;
                }

                &::after {
                    display: none;
                }
            }

            &:not(.expandido)::after {
                content: '...';
                position: absolute;
                right: 60px; 
                bottom: 0;
                background: white;
                padding-right: 3px;
                padding-left: 3px;
            }

            .btn-leia-mais{
                color: #0045B5;
                text-decoration: underline;
                cursor: pointer;

                position: absolute;
                right: 0;
                bottom: 0;
                background: white; 
                padding-left: 5px;
            }

        }

        .row{
            margin-bottom: 32px;
        }
        .box-info{

            &_title{
                color: #666;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 16px;
            }

            &_desc{
                color: #333;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 8px;
                // white-space: nowrap;

                .icones{font-size: 24px;}

                &.links{
                    font-size: 18px;
                }

                &_svg{
                    min-width: 25px;
                }
            }

        }

        .btn{
            width: 100%;
            position: relative;

            .tag-amarelo{
                position: absolute;
                left: -15px;
                top: -9px;
                display: flex;
                padding: 6px 10px 5px 10px;
                align-items: center;
                gap: 8px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                color: black;
                border-radius: 8px;
                background: #FFD100;
            }
        }

        .btn-loading {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
        }
        
        .loading-icon {
            width: 24px;
            height: 24px;
        }
        
        .spinner {
            animation: spin 2s linear infinite;
            transform-origin: center;
        }
        
        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

        .loading {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            @media (min-width: 768px) {
                height: 376px;
            }

            @media (min-width: 992px) {
                width: 391px;
            }
        }
    
        .loading-bg {
            position: absolute;
            width: 96px;
            height: 96px;
        }
    
        .loading-azul {
            position: absolute;
            width: 96px;
            height: 96px;
            animation: spin 2s linear infinite;
        }
    
        .loading-ipiranga {
            position: absolute;
            width: 33px;
            height: 33px;
        }
    }

    .form-check{
        margin-top: 26px;

        &-input{
            border: 2px solid #0045B5;
        }

        &-label{
            color: #797E86;
        }
    }
}

