.event_menu {
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 0 24px;
    margin: 0;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 3px;
        background-color: #D7D9DB;
    }

    @media(min-width: 768px){
        display: none;
    }

    .menu_list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        margin: 0;
        padding: 0;

        .menu_link {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 4px;
            text-decoration: none;
            color: #58595B;
            min-width: 48px;
            max-width: 48px;
            padding: 16px 4px 24px;

            &.active {
                color: #0045B5;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 3px;
                    background-color: #0045B5;
                }                
            }

            .icones {
                font-size: 28px;
                line-height: 1;
            }

            .link_text {
                font-size: 10px;           
                font-weight: 500;
                line-height: 100%; 
                letter-spacing: -0.3px;
            }

            .indicator {
                position: absolute;
                top: 20px;
                right: 7px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                min-width: 16px;
                height: 16px;
                border-radius: 50%;
                text-align: center;
                font-family: 'ipiranga-titulos', sans-serif;
                font-size: 12px;               
                font-weight: 700;
                line-height: normal;
                background-color: #F04C25;
                color: white;               

                &.warning {
                    background-color: #FFD100;
                    color: #0045B5;
                }

                &.success {
                    background-color: #94DE00;
                }
            }
        }
    }
}