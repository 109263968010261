/* ==========================================================================
   Header
   ========================================================================== */

   @keyframes fade-in {
      0%{
        opacity: 0;
      }
       100% {
          opacity: 1;
       }
   }

.header-simples {
   display: flex;
   align-items: center;
   padding-top: 12px;
   padding-bottom: 12px;
   background-color: #0045B5;

   @media (min-width: 992px) {
      padding-top: 0;
      padding-bottom: 0;
      height: 64px;
   }

   .header-area {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;

      @media (min-width: 992px) {
         justify-content: space-between;
      }

      .header-titulo {
         color: #FFF;
         font-family: 'ipiranga-titulos', sans-serif;
         font-size: 20px;
         font-weight: 700;
         margin-bottom: 0;
         
         @media (max-width: 992px) {
            text-align: center;
         }
      }

      .logo {
         display: none;

         @media (min-width: 992px) {
            display: block;
            width: 122px;
         }
      }

      .back_link {
         text-decoration: none;
         font-size: 17px;

         height: 32px;
         min-width: 32px;
         display: flex;
         justify-items: center;
         align-items: center;
         justify-content: center;
         background-color: rgba(255, 255, 255, 0.3);
         border-radius: 50%;
         line-height: 1px;

         &.disabled {
            opacity: 0.2;
            pointer-events: none;
         }

      }
   }

   &.mobile-lg{
      padding-top: 24px;
      padding-bottom: 24px;
      height: auto;

      @media (min-width: 992px) {
         padding-top: 0;
         padding-bottom: 0;
         height: 64px;
      }

      .header-titulo{
         font-size: 32px;
         line-height: 110%;

         @media (min-width: 992px){
            font-size: 20px;
         }
      }
   }

   &.header-evento{

      @media (max-width: 767px){
         position: fixed;
         top: 0;
         left: 0;
         z-index: 10;
         width: 100%;
         background-color: transparent;
         transition: all ease 0.3s;
         padding: 12px 0;
      }

      .header-titulo{
         @media (max-width: 767px){
            opacity: 0;
            transition: all ease 0.3s;
         }
      }

      .back_link{
         @media (max-width: 767px){
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            background-color: rgba(white, 0.3);
            border-radius: 50%;
            font-size: 17px;

            .icones {
               margin-right: 1px;
            }
         }
      }

      .img-usuario{
         @media (max-width: 767px){
            background-color: rgba(white, 0.3);

            .icone-usuario{
               color: white;
            }
         }
      }
      
      &.active{

         @media (max-width: 767px){
            background-color: #0045B5;
         }

         .header-titulo{
            @media (max-width: 767px){
               opacity: 1;
            }
         }
      }
   }
}

.logado {
   .header-area {
      justify-content: space-between;

      .header-titulo{
         font-size: 20px;
      }
   }

   .usuario-area {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
   }

   .img-usuario {
      height: 32px;
      width: 32px;
      background-color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      @media (min-width: 992px) {
         margin-right: 7px;
      }

      .icone-usuario {
         color: #0045B5;
         font-size: 18px;
      }
   }

   .nome {
      margin-bottom: 2px;
      color: #FFF;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 20px;
      font-weight: 700;
      display: flex;

      @media (min-width: 768px){
         font-size: 16px;
         font-weight: 400;
         line-height: 1;
         margin-bottom: 5px;
      }

      .icone-chevron-down {
         display: none;

         @media (min-width: 992px) {
            margin-left: 4px;
            display: block;
         }
      }
   }

   .cnpj {
      color: #E9EAEA;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 87.8%;
      margin-bottom: 0;
      text-align: left;
   }

   .endereco {
      color: white;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 14px;
      margin-bottom: 48px;
      line-height: 140%;

      @media (min-width: 992px) {
         color: #939598;
         font-size: 12px;
         font-weight: 400;
         margin-bottom: 13px;
         padding-left: 16px;
         line-height: initial;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         max-width: 172px;
      }

   }

   .dropdown-menu {
      height: 100dvh;
      width: 100dvw;
      max-width: 100%;
      padding: 15px 24px 24px;
      background-color: #0045B5;
      border: none;
      border-radius: 0;
      box-shadow: none;      
      animation: fade-in .5s alternate forwards;     

      @media (min-width: 992px) {
         top: -46px !important;
         left: -16px !important;
         background-color: #FFF;
         width: initial;
         padding: 0;
         height: initial;
         border-radius: 6px;         
      }

      @media(max-width: 991px){    
         flex-direction: column;
         align-items: center;
         justify-content: flex-end;
         position: fixed !important;
         z-index: 12;
         top: 0;
         left: 0;
         transform: translate(0) !important;
         padding-bottom: 80px;
      }

      &.show {
         display: flex;

         @media(min-width: 992px){
            display: block;
         }
      }
   } 


   .close {
      position: absolute;
      top: 18px;
      right: 29px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;   
      border-radius: 50%;
      background-color: transparent;
      color: white;
      font-size: 16px;
      border: none;

      @media (min-width: 992px) {
         display: none;
      }
   }   

   .dropdown-inside {
      display: flex;
      align-items: center;
      padding: 0;
      cursor: pointer;

      @media (min-width: 992px) {
         // padding: 10px 11px 9px 5px;
         padding: 10px 16px 9px;
      }

      .img-usuario {
         background-color: #0045B5;
         height: 32px;
         width: 32px;
         margin-right: 7px;
      }

      .icone-usuario {
         color: white;
         font-size: 21px;

         @media (min-width: 992px) {
            font-size: 17px;
         }
      }

      .user-info_holder {
         @media(max-width: 991px){
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;          
         }
      }

      .nome {
         color: #FFF;
         font-weight: 500;
         font-size: 18px;
         margin-bottom: 8px;

         @media (min-width: 992px) {
            color: #0045B5; 
            font-weight: 400;
            margin-bottom: 5px;
            font-size: 16px;
         }
         

         .icone-chevron-down {
            // margin-left: 18px;
         }
      }

      .cnpj { 
         margin-bottom: 0;
         color: #FFF;
         font-family: 'ipiranga-titulos', sans-serif;
         font-size: 14px;
         line-height: 140%;

         @media (min-width: 992px) {      
            color: #939598;  
            font-size: 12px;
            font-weight: 400;
            line-height: 87.8%;
         }
      }

   }

   .icon_holder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      min-width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: white; 
      margin-bottom: 16px;     

      .icone-usuario {
         color: #0045B5;
         font-size: 60px;
      }
   }

   .ul-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      list-style: none;
      padding: 0;      
      margin-bottom: 32px;   

      @media (min-width: 992px) { 
         display: block;
         padding: 17px 16px;
         margin-bottom: 16px;
         border-bottom: 1px solid #F2F2F3;
      }
   }

   .links {
      color: white;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 32px;
      font-weight: 500;    
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: all ease-in-out .3s;  
      line-height: 180%; 

      @media (min-width: 992px) {    
         font-size: 16px;
         font-weight: 400;  
         color: #0045B5;
         margin-bottom: 16px;
         line-height: initial;
      }

      &:hover {

         @media (min-width: 992px) {
            opacity: .7;
         }
      }

      .icones {
         margin-right: 10px;
         font-size: 18px;

         @media (min-width: 992px){
            font-size: 15px;
         }
      }
   }

   .sair {
      color: white;
      margin-bottom: 0;

      @media (min-width: 992px) {
         color: #939598;
         margin-bottom: 0;
         padding-left: 15px;
         padding-bottom: 18px;
         padding-left: 16;
      }
   }
}

.extra-nav {
   position: fixed;
   left: 0;
   bottom: 0;
   z-index: 10;
   width: 100%;
   padding: 16px 0;
   background-color: #0045B5;

   @media (min-width: 768px){
      position: static;
      padding: 12px 0;
   }

   .wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media(min-width: 992px){
         width: initial;
         gap: 46px;
      }

      .text {
         color: #FFF;
         text-align: center;       
         font-size: 18px;      
         font-weight: 700;
         line-height: normal;
         margin-bottom: 0;
      }

      .btn {
         width: initial;

         .indicator {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            min-width: 22px;
            height: 22px;
            border-radius: 50%;
            background-color: #F04C25;
            color: #FFF;  
            font-size: 14px;      
            font-weight: 700;
            line-height: normal;        
         }
      }
   }

   .btn {
      width: 100%;

      @media (min-width: 992px) {
         width: 264px;
      }
   }

   .btn-amarelo {
      font-size: 16px;
      color: #18191B;
      height: 56px;
      // width: 100%;

      // @media (min-width: 992px) {
      //    width: 264px;
      // }
   }

   .links {
      color: white;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;
      margin-right: 40px;
      text-decoration: none;
      position: relative;
      transition: all ease-in-out .4s;

      &:hover {
         color: #FFD100;
      }

      &::after {
         content: "";
         display: block;
         width: 100%;
         border-radius: 10px;
         background-color: #FFD100;
         height: 2px;
         position: absolute; 
         bottom: -3px;
         left: 0;
         visibility: hidden;
      }
      
      &.active {
         color: #FFD100;
      }
      
      &.active::after {
         visibility: visible;
      }

      .indicator {
         display: inline-flex;
         align-items: center;
         justify-content: center;
         width: 24px;
         height: 24px;
         min-width: 24px;
         border-radius: 50%;
         background-color: #F04C25;
         color: white;
         margin-left: 8px;
      }
   }
}

.inscricao-confirmada {
   bottom: 82px;
   background-color: #00ADFF; 
   padding: 19px 0 20px 0;

   @media (min-width: 992px) {
      background-color: #0045B5;
      padding: 12px 0;
   }

   .inscricao-info {
      display: flex;
      flex-direction: column;
      text-decoration: none;

      @media (min-width: 992px) {
        border-radius: 6px;
        background: rgba(0, 173, 255, 0.30);
        width: 264px;
        padding: 9.5px 0;
      }
    
      .texto-top {
         color: #FFF;
         text-align: center;
         font-size: 18px;
         font-weight: 700;
         line-height: normal;
         margin-bottom: 9px;

         @media (min-width: 992px) {
            color: #FFF;
            font-size: 16px;
            margin-bottom: 4px;
         }
      }
    
      .texto-bottom {
         color: #FFF;
         text-align: center;
         font-size: 15px;
         font-weight: 500;
         margin-bottom: 0;
         line-height: normal;

        @media (min-width: 992px) {
         font-size: 12px;
        }
      }
   }
}

.inscricao-encerrada {
   bottom: 82px;
   background-color: #FF6900;
   padding: 19px 0 20px 0;

   @media (min-width: 992px) {
      background-color: #0045B5;
      padding: 12px 0;
   }

   .inscricao-info {
      @media (min-width: 992px) {
        background-color: #FF6900;
      }
    
   }
}

.gradiente-menu {
   @media (max-width: 768px) {
      position: fixed;
      top: 0;
      width: 100%;
      height: 101px;
      opacity: 0.4;
      background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
      z-index: 1;
   }

   @media (min-width: 768px) {
      display: none;
   }
}