/* ==========================================================================
   Botoes
   ========================================================================== */

.btn{
   display: inline-flex;
   align-items: center;
   justify-content: center;
   column-gap: 8px;
   padding: 0 36px;
   height: 62px;
   text-align: center;
   font-size: 18px;
   font-weight: 700;
   line-height: normal;
   border-radius: 8px;
   border: 2px solid transparent;
   transition: all ease 0.4s;

   &:hover,
   &:focus,
   &:focus:hover,
   &:active{
      outline: none;
      box-shadow: none;
   }

   &:disabled{
      opacity: 0.3;
   }

   &.md{
      height: 56px;
      font-size: 16px;
   }

   &.sm{
      padding: 0 24px;
      height: 49px;
      font-size: 16px;
   }

   &.xs{
      height: 43px;
      font-size: 14px;
   }

   &-transparent {
      background-color: transparent;
      border: 0;

      &:hover,
      &:focus:hover,
      &:active {
         background-color: transparent;
         border-color: transparent;
      }
   }

   &-azul{
      background-color: #0045B5;
      color: white;

      &:hover,
      &:focus,
      &:focus:hover,
      &:active {
         background-color: darken(#0045B5, 10%);
         color: white;
         border-color: transparent;
      }

      &:disabled{
         background-color: #0045B5;
         color: white;
         opacity: 0.3;
      }
   }

   &-azul-100{
      background-color: #E6ECF8;
      color: #0045B5;

      &:hover,
      &:focus,
      &:focus:hover,
      &:active {
         background-color: #0045B5;
         color: white;
         border-color: transparent;
      }

      &:disabled{
         background-color: #E6ECF8;
         color: #0045B5;
         opacity: 0.3;
      }
   }

   &-azul-claro{
      display: flex;
      flex-direction: column;
      background-color: #00ADFF;
      color: #0045B5;

      &:hover,
      &:focus,
      &:focus:hover,
      &:active {
         background-color: darken(#00ADFF, 10%);
         color: #0045B5;
         border-color: transparent;
      }

      &:disabled{
         background-color: #00ADFF;
         color: #0045B5;
         opacity: 0.3;
      }

      .info{
         margin-top: 4px;
         color: white;
         font-size: 12px;
         font-style: normal;
         font-weight: 500;
         text-decoration: underline;
      }
   }
   
   &-azul-claro-100{
      background-color: #0068FF;
      color: white;
      border-color: transparent;

      &:hover,
      &:focus,
      &:focus:hover,
      &:active {
         background-color: darken(#0068FF, 10%);
      }
   }

   &-amarelo{
      background-color: #FFD100;
      color: black;

      &:hover,
      &:focus,
      &:focus:hover,
      &:active {
         background-color: lighten(#FFD100, 10%);
         color: black;
         border-color: transparent;
      }

      &:disabled{
         background-color: #FFD100;
         color: black;
         opacity: 0.3;
      }
   }

   &-cinza-claro{
      background-color: #F2F2F3;
      color: #0045B5;

      &:hover,
      &:focus,
      &:focus:hover,
      &:active {
         background-color: darken(#F2F2F3, 5%);
         color: #0045B5;
         border-color: transparent;
      }

      &:disabled{
         background-color: #F2F2F3;
         color: 0045B5;
         opacity: 0.3;
      }
   }

   &-cinza{
      border: 2px solid #EEE;
      background: #EEE;
      color: #939598;

      &:hover,
      &:focus,
      &:focus:hover,
      &:active {
         background-color: darken(#EEE, 5%);
         border-color: transparent;
      }

      &:disabled{
         border: 2px solid #EEE;
         background: #EEE;
         color: #939598;
         opacity: 1;
      }
   }

   &-azul-outline{
      background-color: transparent;
      border-color: #0045B5;
      color:#0045B5;
      font-weight: 500;

      &:hover{
         background-color: #0045B5;
         color: white;
      }

      &:disabled{
         background-color: transparent;
         border-color: #0045B5;
         color:#0045B5;
         opacity: 0.3;
      }
   }

   &-full{
      width: 100%;
   }

   &.mobile-full{
      width: 100%;

      @media (min-width: 768px){
         width: auto;
      }
   }

   &-light {
      font-size: 16px;
      font-weight: 400;
   }

   &.btn-reenviar{
      color:  $azul;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      padding: 0;
      height: auto;
      text-align: center;
      cursor: pointer;

      &:active,
      &:focus {
         outline: none;
         border-color:  transparent;
      }
   }


   &-verde{
      background-color: #F1FCD9;
      color: #00551D;

      &:hover,
      &:focus,
      &:focus:hover,
      &:active {
         background-color: darken(#F1FCD9, 5%);
         border-color: transparent;
      }
   }
}