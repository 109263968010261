/* ==========================================================================
   Reset
   ========================================================================== */

html,
body,
main { min-height:100%; scroll-behavior: smooth; }

img, iframe, object, video {
  max-width: 100%;
  width: auto;
  height: auto;
}

form {
  &:after,
  &:before {
    display: table;
    content: " ";
  }
  &:after { clear: both; }
}

textarea {
  max-width: 100%;
  width: 100%;
}

b,
strong { font-weight: 700; }

address { font-size: inherit; }

.container{
  @media (max-width: 767px){
    padding-left: 24px;
    padding-right: 24px;
  }
}