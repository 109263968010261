.titulo{
    font-family: 'ipiranga-titulos';
    font-weight: 400;

    &.xl{
        font-size: 32px;

        @media (min-width: 992px){
            font-size: 56px;
        }
    }

    &.xl-alt{
        font-size: 24px;

        @media (min-width: 768px){
            font-size: 32px;
        }

        @media (min-width: 1200px){
            font-size: 48px;
            line-height: 48px;
        }
    }

    &.lg{
        font-size: 32px;

        @media (min-width: 992px){
            font-size: 40px;
        }
    }

    &.lg-alt{
        font-size: 24px;

        @media (min-width: 992px){
            font-size: 32px;
        }

        @media (min-width: 1200px){
            font-size: 40px;
        }
    }

    &.md{
        font-size: 24px;

        @media (min-width: 992px){
            font-size: 32px;
        }
    }

    &.sm{
        font-size: 20px;

        @media (min-width: 992px){
            font-size: 24px;
        }
    }

    &.xs{
        font-size: 18px;

        @media (min-width: 992px){
            font-size: 20px;
        }
    }

    &.sm,
    &.xs{
        font-weight: 500;

        @media (min-width: 992px){
            font-weight: 400;
        }
    }
}