/* ==========================================================================
    Geral
   ========================================================================== */

body {
  font-family: 'ipiranga-textos', sans-serif;
  font-weight: 400;
  color: #1D1E1E;
  background-color: white;
}

.login-radio_wrapper {
  display: none;
  // flex-wrap: wrap;
  align-items: center;  
  gap: 6px;

  @media(min-width: 992px) {
    display: flex;
  }

  @media(min-width: 1200px) {
    flex-wrap: nowrap;
  }
}

.login_custom-radio {
  position: relative;  
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  @media(min-width: 992px) {
    display: block;
    width: calc(50% - 8px);
  }

  @media(min-width: 1200px) {
    width: initial;
  }

  &_input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked + .checkmark {
      color: #0045B5;
      border-color: #0045B5;

      @media (min-width: 992px){
        background: #0045B5;
        color: white;   
      }

      &::before{
        border-color: #0045B5;
      }

      &::after{
        opacity: 1;
        scale: 1;
      }
    }
  }

  .checkmark {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 18px 16px;
    background: #FFF;
    color: black;
    font-size: 14px;
    line-height: 18px; 
    border: 1px solid #94989E;
    border-radius: 6px;
    transition: 0.4s ease;

    @media (min-width: 992px){
      justify-content: center;
      text-align: center;
      color: #797E86;  
      padding: 10px 16px;
      height: 58px;
      border-radius: 38px;
    }

    &:hover {
      border-color: #0045B5;
    }

    &::before{
      width: 20px;
      height: 20px;
      margin-right: 16px;
      border-radius: 50%;
      border: 1px solid #94989E;
      transition: all ease 0.4s;
    }

    &::after{
      position: absolute;
      top: 22px;
      left: 20px;
      width: 12px;
      height: 12px;
      background-color: #0045B5;
      border-radius: 50%;
      opacity: 0;
      scale: 0;
      transition: all ease 0.4s;
    }

    &::before,
    &::after{
      content: '';
      display: block;

      @media (min-width: 992px){
        content: none;
        display: none;
      }
    }
  }
}

.btn-password {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  outline: none;
  border: 0;
  padding: 0;

  .icone-olho-aberto {
      display: none;
  }

  &.on {
      .icone-olho-aberto {
          display: block;
      }

      .icone-olho-fechado {
          display: none;
      }
  }
}

.login_holder {
  display: flex;
  flex-direction: column-reverse;
  min-height: 100dvh;
  background-color: #0045b5;

  @media(min-width: 1200px) {
    flex-direction: row;
    height: 100vh;
    background-color: transparent;
  }

  .intro {
    font-family: 'ipiranga-titulos', sans-serif;
    color: white;
    margin-bottom: 32px;

    @media(min-width: 1200px) {
      margin-bottom: 16px;
    }

    @media(min-width: 1400px) {
      margin-bottom: 24px;
    }

    .intro_text {
      font-size: 18px;
      font-style: normal;
      margin-bottom: 0;

      @media(min-width: 1200px) {
        font-size: 20px;
        color: #18191B;
        line-height: 42px;
      }
    }

    .intro_title {
      font-size: 32px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0;

      @media(min-width: 1200px) {
        color: #0045B5;
        font-size: 48px;
        line-height: 42px;
      }

    }
  }

  .indicator_text {
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 14px;

    @media(min-width: 1200px){
      margin-bottom: 16px;
      color: black;
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    height: 130px;

    @media(min-width: 1200px) {
      height: 100%;
      width: 50vw;
    }

    .desk_content {
      max-width: 511px;
      margin-bottom: 62px;
      display: none;

      @media (min-width: 1200px) {
        display: block;
      }

      .titulo-desk {
        color:  #FFF;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 32px;
      }

      .texto-desk {
        color:  #FFF;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .mobile_content {
      position: relative;
      z-index: 2;
      text-align: center;

      @media(min-width: 1200px) {
        display: none;
      }
    }

    .login-bg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      width: 100%;
      background-color: #0045B5;

      @media (min-width: 992px) {
        background-image: url('../img/login-bg.png');
        background-position: left;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .video_holder{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 1;
      background-color: transparent;

      .video {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-height: 100%;
        min-width: 100%;
        max-width: inherit;
      }
    }
  }

  .left-side {
    display: flex;
    padding: 24px;
    flex: 1;
    background-color: white;
    border-radius: 20px 20px 0 0;

    @media(min-width: 1200px) {
      align-items: center;
      justify-content: center;
      padding: 24px;
      height: 100%;
      width: 50vw;
      background-color: transparent;
    }

    .content_holder {
      width: 100%;
      margin: 0 auto;

      @media (min-width: 992px){
        max-width: 405px;
      }

      @media(min-width: 1200px) {
        height: 80%;
        overflow: auto;
      }

      @media (min-width: 1200px) and (min-height: 600px){
        height: auto;
      }
    }

    .btn {

      @media (min-width: 1200px) {
        height: 48px;
        font-size: 16px;
      }

      @media (min-width: 1400px) {
        height: 62px;
        font-size: 18px;
      }

    }

    .btn + .btn{
      margin-top: 21px;

      @media(min-width: 1200px) {
        margin-top: 16px;
      }

      @media(min-width: 1400px) {
        margin-top: 21px;
      }
    }
  }

}

.radios-mobile{

  .login_custom-radio + .login_custom-radio{
    margin-top: 16px;
  }
}

.divisor{
  position: relative;
  font-size: 16px;
  color: black;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  span{
    position: relative;
    z-index: 2;
    padding: 6px 16px;
    background-color: white;
  }

  &::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 1px;
    transform: translateY(-50%);
    background-color: #C9CBCF;
  }
}

.step-login{
  display: none;

  .btn:first-of-type{
    margin-top: 32px;
  }

  .form-control {

    @media (min-width: 1200px) {
      height: 48px;
    }

    @media (min-width: 1400px) {
      height: 54px;
    }
  }
}

.step-selecione{

  .radios-mobile{
    margin-bottom: 32px;
  }
}

.tooltip {

  .tooltip-inner {
    border: 1px solid #797E86;
    background-color: white;
    color: #797E86;
    font-weight: 600;
    text-align: initial;
  }

  .tooltip-arrow {
    background-color: white !important;

    &::before {
      border-top-color: #797E86 !important;
    }
  }
}

.onboarding_sect {
  min-height: calc(100vh - 65px);
  padding: 24px 0;
}

.onboarding_carousel {
  margin-top: 16px;

  @media(min-width: 992px) {
    margin-top: 52px;
    padding-bottom: 40px !important;
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-pagination {
    bottom: 100px !important;

    @media(min-width: 992px) {
      width: fit-content !important;
      bottom: 0 !important;
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: #D1D3D4;
      opacity: 1;

      &-active {
        background-color: #0045B5;
      }
    }
  }

  .onboarding_card {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media(min-width: 992px) {
     flex-direction: row;
      align-items: center;
    }

    .image {
      display: block;
      max-width: 272px;
      margin: 0 auto 8px;

      @media(min-width: 992px) {
        max-width: initial;
        margin: 0 26px 0 0;
      }
    }

    .content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;

      .info {
        margin-bottom: 100px;

        @media(min-width: 992px) {
          margin-bottom: 32px;
        }

        .title {
          color: #18191B;
          text-align: center;
          font-family: 'ipiranga-titulos', sans-serif;
          font-size: 32px;
          line-height: normal;
          margin-bottom: 24px;

          @media(min-width: 992px) {
            text-align: initial;
            font-size: 48px;
            margin-bottom: 32px;
            line-height: 48px;
          }
        }

        .desc {
          color: #797E86;
          text-align: center;
          font-size: 16px;
          line-height: normal;
          margin-bottom: 0;

          @media(min-width: 992px) {
            text-align: initial;
            font-size: 18px;
          }
        }
      }

      .btn {
        display: inline-flex;
        width: 100%;

        @media(min-width: 992px) {
          width: initial;
          min-width: 200px;
        }
      }
    }
  }
}


.btn-voltar{
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
  padding: 0;
  background-color: transparent;
  color:  #18191B;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
  text-decoration: none;
  outline: none;
  transition: all ease 0.3s;

  &:hover {
    opacity: 0.8;
  }

  .icones{
    font-size: 18px;
  }

  &.swiper-button-disabled{
    opacity: 0;
  }
}

.conteudo-login{
  position: relative;
  background-color: white;
  min-height: calc(100dvh - 60px);
  padding: 24px 0;
  border-radius: 20px 20px 0 0;

  @media (min-width: 992px){
    height: calc(100vh - 65px);
    min-height: 500px;
    padding: 0;
    overflow: hidden;
    background-color: transparent;
    border-radius: 0;
  }

  .container{
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .row{

    @media (min-width: 992px){
      flex: 1;
      align-items: center;
    }
  }

  .info{
    display: none;

    @media(min-width: 992px) {
      display: block;
      padding-right: 90px;
    }

    .info-title{
      color: #18191B;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @media(min-width: 1200px) {
        font-size: 48px;
      }
    }

    .info-paragrafo{
      color:  #797E86;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      .link{color: #0045B5}
    }

  }

  .box-checar{
    position: relative;

    @media (min-width: 992px){
      padding: 38px 30px 46px;
      background-color: white;
      border-radius: 16px;
      border: 1px solid #E7E7E7;
    }

    &.pb-36{
      padding-bottom: 36px;
    }

    .receber-text {
      color: #000;  
      font-size: 14px;
      margin-bottom: 16px;
    }

    .receber-wrapper {
      margin-bottom: 24px;

      .form-label {
        line-height: 1;
        margin-bottom: 4px;
      }
    }

    .texto{
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
      margin-bottom: 16px;
      text-align: center;

      @media(min-width: 992px) {
        margin-bottom: 18px;
        text-align: left;
      }
    }

    .title {
      color: #18191B;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 24px;
      line-height: normal;
      margin-bottom: 10px;
      font-weight: 400;

      @media(min-width: 992px) {
        font-size: 32px;
        margin-bottom: 16px;
      }
    }

    .box_text {
      color: #58595B;      
      font-size: 14px;    
      line-height: normal;

      @media(min-width: 992px) {
        font-size: 16px;
      }
    }
  }

  .box-sucesso {
    
    @media (min-width: 992px){
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .title {

      @media (min-width: 992px){
        text-align: center;
        margin-bottom: 40px;
        margin-top: 24px;
      }
    }
  }

  .flutuantes{
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-47%, -50%);

    @media(min-width: 992px) {
      display: block;
    }
  }

  .img-flutuante{
    max-width: initial;

    &.azul{
      position: relative;
      z-index: 4;
    }

    &.laranja,
    &.amarelo,
    &.amarelo-claro{
      position: absolute;
      top: 0;
      left: 0;
      rotate: 0;
      transition: all ease 0.6s;
    }

    &.laranja{
      z-index: 3;
      transition-delay: 0.1s;

      &.in{
        rotate: 15deg;
      }
    }

    &.amarelo{
      z-index: 2;
      transition-delay: 0.2s;

      &.in{
        rotate: 30deg;
      }
    }

    &.amarelo-claro{
      z-index: 1;
      transition-delay: 0.3s;

      &.in{
        rotate: 45deg;
      }
    }
  }  

  .btn-voltar{
    color: #0045B5;

    @media (min-width: 992px){
      color: #18191B;
    }
  }
}

.atualize-dados {
  padding: 24px 0 40px;

  @media (min-width: 992px) {
    padding: 32px 0 75px;
  }

  .img-avatar {
    display: none;
    margin-bottom: 22px;

    @media(min-width: 992px){
      max-width: 360px;
      margin-bottom: 0;
    }
  }

  .convidado-masc {
    .avatar-convidado-masc {
      display: block;
    }
  }

  .convidado-fem {
    .avatar-convidado-fem {
      display: block;
    }
  }

  .revendedor-masc {
    .avatar-revendedor-masc {
      display: block;
    }
  }

  .revendedor-fem {
    .avatar-revendedor-fem {
      display: block;
    }
  }

  .funcionario-masc {
    .avatar-funcionario-masc {
      display: block;
    }
  }

  .funcionario-fem {
    .avatar-funcionario-fem {
      display: block;
    }
  }

  .texto-dados-area {
    text-align: center;
    margin-bottom: 24px;

    @media (min-width: 992px) {
      text-align: left;     
      margin-bottom: 0;
    }
  }

  .texto-dados-titulo {
    color: #18191B;
    font-family: 'ipiranga-titulos', sans-serif;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 24px;

    @media (min-width: 992px) {
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 16px;
    }
  }

  .texto-dados {
    color: #797E86;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;

    @media (min-width: 992px) {
      font-size: 18px;
    }
  }

  .infos {

    .titulo {
      color: #18191B;
  
      &.lg{
        margin-bottom: 16px;
  
        @media (min-width: 992px) {
          margin-bottom: 32px;
        }
      }
  
      &.md{
        margin-bottom: 32px;
      }
  
      &.sm{
        margin-top: 32px;
        margin-bottom: 16px;
      }
    }

    &-texto {
      display: none;

      @media (min-width:992px) {
        color:  #797E86;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 16px;
        display: block;
      }
    }

    .btn-area {
      margin-top: 32px;   

      @media (min-width: 992px) {
        margin-top: 56px;       
      }

      .btn {
        display: flex;
        width: fit-content;
      }

      .btn-azul {
        margin-bottom: 32px;
        height: 56px;

        @media (min-width: 992px) {
          margin-bottom: 0;
        }
      }

      .btn-azul-outline {
        height: 56px;
        margin-top: 56px;
      }
    }

    .form-check {
      margin-bottom: 14px;

      .form-check-label {
        font-size: 16px;
      }
    }

    .link {
      transition: all ease-in-out .4s;
      color: #0045B5;
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      text-decoration-line: underline;

      &:hover {
        text-decoration: none;
        color: darken( #0045B5, 10%);
      }
    }

    .btn-salvar {
      margin-top: 24px;   
  
      @media (min-width: 992px) {
        margin-top: 56px;       
      }
    }
  
    .btn-cancelar {
      margin-top: 16px;
  
      @media (min-width: 992px) {
        margin-top: 32px;
      }
    }
  }

}

.atualize-dados-v2 {
  border-radius: 20px 20px 0 0;
  background-color: white;

  @media (min-width: 992px) {
    border-radius: 0;
    background-color: unset;
  }

  .infos {
    .titulo {
      &.sm {
        margin-bottom: 8px;
      }
    }
  }

  .texto-dados-area {
    text-align: left;
  }

  .texto-dados-titulo {
    font-size: 24px;
    margin-bottom: 8px;

    @media (min-width: 992px) {
      font-size: 48px;
      margin-bottom: 24px;
    } 
  }

  .texto-dados {
    color: #18191B;
  }

  .btn-voltar {
    margin-top: 8px;
    margin-bottom: 24px;

    @media (min-width: 992px) {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .infos {
    .btn-salvar {
      margin-top: 32px;
  
      @media (min-width: 992px) {
        margin-top: 32px;
      }
    }
  
    .btn-cancelar {
      margin-top: 32px;
    }
  }

}

.ver-camisa-modal_handler{
  display: block;
  text-align: right;
  font-size: 12px;
  color: #0045B5;
  text-decoration: none;
}

.password-validation_wrapper {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 4px;

  .validation_line {
    display: flex;
    margin: 16px 0 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .icon-check_holder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      min-width: 12px;
      border-radius: 50%;
      margin-right: 6px;
      background-color: #C9CBCF;

      .icones {
        color: white;
        font-size: 6px;
      }

      &.active{
        background-color: #0045B5;
      }
    }

    .validation_text {
      color: #A2A2A5;
      font-size: 12px;
      line-height: 120%;
      white-space: nowrap;
      margin-bottom: 0;
    }

    &.checked {
      .icon-check_holder {
        background-color: #0045B5;
      }
    }
  }
}

.c-cinza-chumbo {
  color: #58595B !important;
}

.inputs-codigo_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .form-control {
      width: 48px;
      max-width: 48px;
      height: 51px;  
      padding: 19px 16px;  
      text-align: center;  
      font-size: 18px;

      @media(min-width: 992px) {
        width: 67px;
        max-width: 67px;
        height: 70px;  
        font-size: 20px;
      }
  }

  &.error{
    position: relative;

    .form-control{
      border: 1px solid  #F04C25;
    }

    &::after{
      content: 'código inválido';
      position: absolute;
      bottom: -4px;
      right: 0;
      display: block;
      color: #F04C25;
      font-size: 14px;
      transform: translateY(100%);
    }
  }
}

.sobre{  
  // padding-bottom: 40px;

  @media(min-width: 768px){
    padding-top: 24px;
    // padding-bottom: 74px;
  }

  .breadcrumb{
    margin-bottom: 32px;

    @media (max-width: 768px) {
      margin-bottom: 0;

      &:has(+ .row) {
        margin-bottom: 32px;
      }
    }
  
    @media(min-width: 992px){
      margin-bottom: 40px;
    }
  }

  .info-sobre{
    color: #0045B5;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;

    @media (min-width: 768px) {
      margin-bottom: 16px;
    }

    @media(min-width: 992px) {
      font-size: 23px;
    }
  }

  .info{
    font-size: 14px;

    @media(min-width: 992px) {
      font-size: 16px;
    }

    .titulo{
      color: #18191B;
    }
  }

  .info-paragrafo{
    margin-top: 0;
    margin-bottom: 0;
    color: #303236;
    
    & + .info-paragrafo{
      margin-top: 16px;

      // @media(min-width: 1200px) {
      //   margin-bottom: 24px;
      // }
    }

    .detalhe{
      color: #94989E;
    }
  }

  .img-sobre{
    display: block;
    margin: 24px auto;
    width: 100%;
    max-width: 327px;

    @media(min-width: 992px) {
      margin-top: 0;
      margin-bottom: 0;
      max-width: 424px;
    }
  }

  .ler-mais_btn {
    display: block;
    width: fit-content;
    margin: 16px auto 0;
    color: #0045B5;    
    font-size: 14px; 
    line-height: 120%;

    @media(min-width: 768px){
      display: none;
    }
  }

  .content_overflow {    
    @media(max-width: 767px){
      position: relative;
      overflow: hidden;
      height: 204px;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 80px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
      }
    }

    &.visible {
      height: initial;
      overflow: visible;

      &::after {
        display: none;
      }
    }
  }
}

.italic {
  font-style: italic;

  .info-paragrafo{
    &:last-of-type {
      font-style: normal;

      .c-azul {
        font-size: 18px;
      }

      .detalhe {
        font-size: 14px;
      }
    }
  }
}

.breadcrumb{
  .lista{
    display: flex;
    padding: 0;
    list-style-type: none;
    gap: 8px;
    margin-bottom: 0;

    &_link{
      display: inline-flex;
      align-items: center;
      color:  #76777A;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      .main-link{
        color:#0045B5 ;
        text-decoration-line: underline;
        transition: all ease 0.4s;

        &:hover{
          text-decoration-line: none;
          opacity: 0.7;
        }
      }

      & + .lista_link{
        &::before{
          content: '\e919';
          display: block;
          margin-right: 8px;
          font-family: 'icones-ipiranga-eventos' !important;
          color: #7A8598;
          font-size: 8px;
        }
      }
    }
  }
}

.section-video{
  padding-top: 8px;
  padding-bottom: 50px;

  @media(min-width: 992px){
    padding-top: 56px;
    padding-bottom: 45px;
  }

  .video-box{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 32px;

    .titulo{
      color: #001C48;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 24px;

      @media(min-width: 992px){
         font-size: 48px;
      }
    }
    
  }

  .videoWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
    background-size: cover;
    background-position: center;
    border-radius: 8px;

    @media(min-width: 992px){
      max-width: 742px;
    }

    .btn-play{
      width: 40px;
      display: block;
      position: absolute;
      top: calc((100% / 2) - 20px);
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      cursor: pointer;

      @media(min-width: 992px){
        width: 92px;
        top: calc((100% / 2) - 46px);
      }

      &:hover{
        opacity: .7;
      }
    }

    .video{
      width: 100%;
      border-radius: 8px;
    }
    
  }

  .btn{
    width: 100%;

    @media(min-width: 992px){
      max-width: 168px;
    }
  }
}

.evento_banner {
  position: relative;  
  color: white; 

  @media (max-width: 992px) {
    background-image: none !important;
  }
 
  @media(min-width: 992px) {   
    display: flex;
    align-items: stretch;
    justify-content: flex-start; 
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
    min-height: 550px;  
  }

  .confirmada_wrapper {
    display: none;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding: 16px;
    background-color: #F1FCD9;

    .icon_holder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      min-width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #98DB09;

      .icon {
        width: 12px;
      }
    }

    .text {
      color: #000;  
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 20px;
      font-weight: 500;
      line-height: normal;
    }
  }

  .container {
    position: relative;

    @media(min-width: 992px){
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  .bg_holder {
    background-color: white; 
    
    @media(min-width: 768px) {
      background-color: #00266E;
    }

    @media(min-width: 992px) {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 52px 0;
      height: inherit;
      background-color: transparent;
    }

    &.finalizar {    
      .banner_content {
       .inscrever_btn {
        display: none;
       }

       .confirmado_btn {
        display: none;
       }
  
       .finalizar_btn {
        display: flex;   
        font-size: 20px;
        border-radius: 10px; 
  
        @media(min-width: 992px){
          display: none;
        }
       }
      }
    }

    &.confirmado {
      .confirmada_wrapper {
        display: none;
      }

      .banner_content {
       .inscrever_btn {
        display: none;
       }
  
       .confirmado_btn {
        display: flex;
        gap: 10px;
        background-color: #F1FCD9;
        color: #00551D;
        border: 1px solid #F1FCD9;
        cursor: inherit;
        font-size: 20px;
        border-radius: 10px;
        pointer-events: none;

        .icones {
          color: #98DB09;
        }
  
        @media(min-width: 768px){
          display: none;
        }
  
        .icones {
          font-size: 24px;
        }
       }
      }
    }
  }

  .mobile-image_holder {
    position: relative;
    height: 320px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media(min-width: 992px) {
      display: none;
    }

    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 180px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%);
    }    
  }

  .voltar_link {  

    @media(min-width: 992px) {
     position: absolute;
     left: 8px;
     top: -24px;    
    }

    &.preto {
      color: #18191B;
    }
  }

  .banner_content {
    padding: 24px 0;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 22px 0 33px;
    }

    @media(min-width: 992px) {
      padding: 0;
    }

    .banner_title {
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 32px;  
      font-weight: 700;
      line-height: 110%;
      margin-bottom: 16px;

      @media(min-width: 992px) {
        font-size: 56px;  
        // margin-bottom: 27px;
      }
    }

    .banner_title-mobile {
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 24px;  
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0;
      color: #18191B;
      order: 1;
    }

    .inscrever_btn, .editar_btn, .finalizar_btn, .confirmado_btn {
      margin-bottom: 20px;

      @media (max-width: 768px) {
        order: 4;
        margin-bottom: 0;
      }

      @media(min-width: 768px){
        display: none;
      }
    }

    .inscrever_btn {
      color: #0045B5;   
      font-size: 20px;
      border-radius: 10px;  
    }

    .editar_btn {
      display: none;
    }

    .finalizar_btn {
      display: none;
      gap: 8px;

      .indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #FFF;
        font-family: 'ipiranga-titulos', sans-serif;
        font-size: 14px;     
        line-height: normal;
        background-color: #F04C25;
      }
    }

    .confirmado_btn {
      display: none;
    }

    .infos_wrapper {      
      display: flex;
      align-items: stretch;
      gap: 16px;      

      @media(max-width: 768px) { 
        order: 2;
        gap: 8px;
      }

      @media(min-width: 768px) { 
        display: block;     
        gap: 20px;
        margin-bottom: 24px;
      }

      @media(min-width: 992px) { 
        display: flex;
        max-width: 40%;
      }

      .info_holder {
        display: flex;
        align-items: center;   
        height: inherit;
        color: black;
        padding: 10px 16px;
        background-color: #F2F2F3;    
        border-radius: 6px;

        @media(min-width: 768px){
          padding: 0;
          border-radius: 0;
          background-color: transparent;
          color: white;
          margin-bottom: 16px;
        }

        @media(min-width: 992px){
          margin-bottom: 0;
        }

        &:nth-of-type(2){
          flex: 1;
        }

        .info_text {
          font-size: 14px;      
          font-weight: 500;
          line-height: 110%;
          margin: 0 0 0 8px;

          @media(min-width: 992px) {
            font-size: 18px; 
          }
        }

        .icones{         
          font-size: 18px;

          @media(min-width: 768px) {
            color: #FFD100;
            font-size: 22px;
          }
        }
      }

      &_texto{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }

    .banner_desc {
      font-size: 14px;  
      font-weight: 500;
      line-height: 160%;
      margin-bottom: 16px;

      @media(min-width: 992px) {
        font-size: 18px;  
      }
    }

    .banner_alert {
      display: flex;
      align-items: center;
      color: #FFD100;
      margin-bottom: 20px;

      @media(min-width: 992px) {
        margin-bottom: 40px; 
      }

      .icon_holder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 5px;
        // background-color: #FFD100;

        @media(min-width: 992px) {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }

      .alert_text {
        margin-bottom: 0;
        font-size: 14px;       
        font-weight: 500;
        line-height: normal;

        @media(min-width: 992px) {
          font-size: 16px;   
        }
      }    
    }

    .banner_timer-wrapper {
      width: fit-content;
      align-items: center;
      gap: 32px;
      padding: 11px 32px;
      border-radius: 8px;
      background: #0045B5;
      backdrop-filter: blur(7.126984119415283px);
      order: 3;
      width: 100%;
      justify-content: center;

      @media (min-width: 768px) {
        order: inherit;
        background: rgba(0, 0, 0, 0.30);
        width: fit-content;
        justify-content: flex-start;
        padding: 18px 32px;
      }

      .text {                
        font-family: 'ipiranga-titulos', sans-serif;   
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 0;
      }

      .timer {
        display: flex;
        align-items: flex-start;     

        .time_box {
          font-family: 'ipiranga-titulos', sans-serif;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;        

          .number {   
            color: #FFF;
            font-family: 'ipiranga-titulos', sans-serif;
            font-size: 28px;           
            font-weight: 700;
            line-height: normal;

            @media (min-width: 768px) {
              font-size: 34px;      
            }
          }

          .timer_text {  
            color: rgba(255, 255, 255, 0.70);
            font-family: 'ipiranga-titulos', sans-serif;
            font-size: 13px;        
            line-height: normal;

            @media (min-width: 768px) {
              font-size: 16px;      
            }
          }
        }

        .separator {   
          color: #FFF;
          font-family: 'ipiranga-titulos', sans-serif;
          font-size: 28px;           
          font-weight: 700;
          line-height: normal;

          @media (min-width: 768px) {
            font-size: 34px;      
          }
        }

        .days_box {
          margin-right: 16px;
        }
      }
    }
  }

  &-interna {
    border-radius: 20px 20px 0 0;
    overflow: hidden;

    @media(min-width: 992px) {     
      border-radius: 0;
      min-height: 250px;
    }     

    .mobile-image_holder {
      height: 155px;
    }   

    .banner_content {

      .banner_title {
        font-weight: 500;
        @media(min-width: 992px) {
          margin-bottom: 20px;
        }
      }     

      .infos_wrapper {
        margin-bottom: 0;

        .info_holder {
          
          .icones {
            color: white;
            font-size: 16px;
          }

          .info_text {
            font-size: 14px;
            font-weight: 400;

            @media(min-width: 992px) {
              font-size: 16px;
            }
          }
        }
      }
    }   

    &.dark-colors {
      .voltar_link {      

        @media(min-width: 992px){
          color: #18191B;
        }
      }
      .banner_content {
        .banner_title {      

          @media(min-width: 992px){
            color: #18191B;
          }
        }     

        .infos_wrapper {
          .info_holder {
            .icones, .info_text {           

              @media(min-width: 992px){
                color: #18191B;
              }
            }
          }
        }       
      }
    }
  }  

  &.dark-colors {    
    .banner_content {
      .banner_title, .banner_desc {
       
        @media(min-width: 768px){
          color: white;
        }

        @media(min-width: 992px){
          color: #18191B;
        }
      }

      .infos_wrapper {
        .info_holder {
          .icones {
            @media(min-width: 992px){
              color: $azul;
            }
          }

          .info_text {          

            @media(min-width: 768px){
              color: white;
            }

            @media(min-width: 992px){
              color: #18191B;
            }
          }
        }
      }
    }
  } 

  &--tranportadoras{
    @media (min-width: 992px) and (max-width: 1199px){
      .banner_content{
        .banner_title{
          font-size: 48px;
        }
      }
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
      min-height: 400px; 

      .banner_content{
        transform: translateY(16px);

        .banner_title{
          font-size: 32px;

          .break{
            display: none;

            @media(min-width: 992px){
              display: block;
            }

            @media(min-width: 1200px) and (max-width: 1600px){
              display: none;
            }
          }
        }
        .banner_desc{
          font-size: 16px;
        }

        .infos_wrapper{
          .info_holder{
            .info_text{
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  &_clube-2025{
    .mobile-image_holder{
      background-position: top;
    }
  }

  &.interna-v2{

    @media(min-width: 992px){
      min-height: 250px;
    }
  }
}

.banner-convencao {
  .banner_content {
    .infos_wrapper {
      @media (min-width: 992px) {
        max-width: 48%;
      }

      @media (min-width: 1200px) {
        max-width: 41%;
      }

      @media (min-width: 1400px) {
        max-width: 40%;
      }

      .info_holder {
        .info_text {
          font-size: 14px;      
          font-weight: 500;
          line-height: 110%;
          margin: 0 0 0 14px;
    
          @media(min-width: 992px) {
            font-size: 18px; 
          }
        }
    
        .info_text-local {
          @media(min-width: 992px) {
            font-size: 16px; 
          }
        }
      }
    }
  }
}

.como-chegar_sect {
  padding: 40px 0 40px;
  background-color: #E6ECF8;

  @media(min-width: 992px) {
    padding: 80px 0 80px;
  }

  .sect_indicator {
    display: block;
    color: #0045B5;
    font-family: 'ipiranga-titulos', sans-serif;
    font-size: 16px;  
    font-weight: 700;
    line-height: normal;
    margin-bottom: 16px;

    @media(min-width: 992px) {
      font-size: 23px;  
    }
  }

  .title {
    color: #18191B;
    font-family: 'ipiranga-titulos', sans-serif;
    font-size: 24px;
    line-height: normal;
    margin-bottom: 24px;

    @media(min-width: 992px) {
      font-size: 48px;  
      margin-bottom: 40px;
    }
  }

  .como-chegar_card {
    width: 100%;
    padding: 38px 24px 26px;
    border-radius: 8px;
    background: #0045B5;
    text-align: center;

    @media(min-width: 992px) {
      border-radius: 16px;
    }

    .icon_holder {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      margin-bottom: 16px;

      @media(min-width: 992px) {
        height: 61px;
      }

      .icon {
        max-height: 100%;
      }

      .carro_icon {
        height: 32px;

        @media(min-width: 992px) {
          height: 42px;
        }
      }
    }

    .card_desc {
      color: white;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 16px;    
      line-height: normal;
      margin-bottom: 4px;

      @media(min-width: 992px) {
       font-size: 24px; 
       margin-bottom: 20px; 
      }
    } 
    
    &.card-cinza{
      background-color: #F4F4F5;

      .icon_holder{

        .carro_icon {
          height: initial;
        }
      }
      .card_desc{
        color: black;
      }
    }

    &.card-branco{
      background-color: white;

      .icon_holder{
        .carro_icon {
          height: initial;
        }
      }
      .card_desc{
        color: black;
      }
    }

    &.card-azul-claro{
      background-color: #EBF2FF;

      .icon_holder{

        .carro_icon {
          height: initial;
        }
      }
      .card_desc{
        color: black;
      }
    }
  }

  .info_box {
    width: 100%;  
    padding: 24px;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 16px;

    @media(min-width: 992px) {
      height: 100%;
      padding: 36px 40px 40px;
      margin: 0;
    }

    .box_title {
      color: #0045B5;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 24px;  
      line-height: normal;
      margin-bottom: 14px;

      @media(min-width: 992px) {
        font-size: 40px; 
      }
    }

    .desc_tel {
      max-width: 200px;

      @media (min-width: 390px) {
        max-width: initial;
      }
    }

    .box_desc {
      margin-bottom: 16px;
      color: #303236;   
      font-size: 14px;  
      line-height: normal;

      @media(min-width: 992px) {
        font-size: 16px; 
        margin-bottom: 0;
      }

      & + .box_desc {
        margin-top: 12px;
      }
    }

    &-navegacao {
      padding-bottom: 32px;

      @media(min-width: 992px) {
        overflow: hidden;
        display: flex;
        align-items: stretch;
        padding: 0;
      }

      .content_holder {        

        @media(min-width: 992px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 2;
          background-color: white;
          border-radius: 20px;
          padding: 36px 30px 48px;
          max-width: 370px;
        }
      }

      .iframe-map {
        width: 100%;
        aspect-ratio: 3/2;
        margin: 24px 0;

        @media(min-width: 992px) {
          height: 100%;
          margin: 0 0 0 -20px;
          aspect-ratio: 16/8;
        }

        @media(min-width: 1200px) {         
          aspect-ratio: 16/7;
        }

        @media(min-width: 1400px) {          
          aspect-ratio: 16/6;
        }
      }
      
      .btn-azul {
        width: 100%;

        @media(min-width: 992px) {
          margin-top: 24px;
        }
      }
    }
  }
  

  &.localizacao{
    background-color: white;
    padding-bottom: 80px;
    padding-top: 16px;

    .breadcrumb-padrao{
      margin-bottom: 24px;

      @media(min-width: 992px){
        margin-bottom: 40px; 
      }
    }

    .title{ margin-bottom: 32px; }

    .info_box{
      position: relative;
      background-color: #F2F2F3;

      @media(max-width: 992px){
        overflow: hidden;
      }

      &.bg-azul-claro{
        background-color: #EBF2FF;
      }
    }

    .box_title{
      font-weight: 400;
    }

    .btn.btn-azul-outline{
      width: 167px;
      margin-top: 40px;

      @media(min-width: 992px) {
        margin-top: 80px;
      }
    }

    .info_box-navegacao .content_holder{
      background-color: #F2F2F3;

      &.bg-azul-claro{
        background-color: #EBF2FF;
      }
    }
  }

  &.smaller {
    @media(min-width: 992px){
      padding: 64px 0 80px;
    }

    .info_box-navegacao {
      .content_holder {
        padding-left: 28px;
        padding-right: 28px;
      }
    }
  }
}

.section-faq {
  padding-top: 29px;

  @media(min-width: 992px) {
    padding-top: 63px;
  }

  .texto-indicador {
    color: #0045B5;

    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 16px;

    @media(min-width: 992px) {
      font-size: 23px;
    }
  }

  .titulo-faq {
    color:  #18191B;
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;

    @media(min-width: 992px) {
      font-size: 48px;
    }
  }

  .texto-info {
    color: #303236;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 16px;
    margin-top: 8px;

    @media (min-width: 992px) {
      margin-bottom: 24px;
      margin-top: 16px;
    }
  }

  .intro {
    color: #303236;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 32px;
  }

  .filtro {
    display: flex;
    gap: 8px;
    min-width: max-content;
    margin-bottom: 24px;

    @media(min-width: 992px) {
      margin-bottom: 32px;
      gap: 24px;
    }

    .btn {
      padding: 8px 24px;
      font-size: 16px;
      font-weight: 500;

      @media(min-width: 992px) {
        padding: 16px 24px;
      }
    }

    &-wrapper{
      padding-top: 4px;
      padding-left: 24px;
      padding-right: 24px;
      width: calc(100% + 48px);
      margin-left: -24px;
      overflow: auto;
      scroll-behavior: smooth;

      @media (min-width: 768px){
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        margin-left: 0;
        overflow: auto;
      }

      // &::-webkit-scrollbar {
      //   // display: none;
      //   opacity: 0;
      // }
    }    
  }
}

.accordion-faq{

  .accordion-item{
    border-radius: 10px;
    background: #E6ECF8;
    border: 0;
    margin-bottom: 8px;

    .accordion-header{

      .accordion-button{
        padding: 16px 48px;
        background: #E6ECF8;
        color:  #0045B5;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        border-radius: 10px;
        border: 0;
        outline: none;
        box-shadow: none;

        @media(min-width: 992px) {
          padding: 22px 63px;
        }
      }

      .accordion-button::after{
        display: none;
      }
      
      .accordion-button::before{
        content: "";
        width: 24px;
        height: 24px;
        position: absolute;
        top: calc((100% / 2) - 12px);
        left: 16px;
        background-image: url('../img/arrow-narrow-right.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transform: rotate(0);
        transition: all .3s ease;

        @media(min-width: 992px) {
          left: 19px;
        }
      }

      .accordion-button:not(.collapsed)::before{
        transform: rotate(90deg);
      }
    }
    .accordion-body{
      padding: 0 16px 16px 48px;

      @media(min-width: 992px) {
        padding: 0  63px 22px;
      }
    }
  }
}

.btn-central-ajuda{
  text-decoration-line: underline;
  color: #0045B5;
  border: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.modal-central-ajuda{
  overflow: hidden;

  .modal-dialog{
    max-width: 100%;
    
    @media(max-width: 991px) {
      align-items: flex-end;
      margin: 0;
      min-height: 100dvh;
    }

    @media(min-width: 992px) {
      max-width: 503px;
    }

    .modal-content{
      border-radius: 20px 20px 0 0;

      @media(min-width: 992px){
        border-radius: 20px;
      }
    }
  }

  .modal-header{
    border-bottom: 0;
    padding-top: 64px;
    padding-right: 30px;
    padding-bottom: 8px;
    position: relative;

    @media(min-width: 992px){
      padding-top: 30px;
    }

    &::after{
      content: "";
      position: absolute;
      top: 24px;
      left: 50%;
      transform: translateX(-50%);
      width: 102px;
      height: 5px;
      background-color: #D9D9D9;

      @media(min-width: 992px){
        display: none;
      }

    }

    .btn-close{
      background-color: transparent;
      background-image: none;
      padding: 0;
      line-height: normal;
      color: #0045B5;
      transition: all .3s ease;
      opacity: 1;

      &:hover{
        opacity: .7;
      }
    }
  }

  .modal-body{
    padding: 0 24px 48px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .titulo {
        color: black;
        font-size: 24px;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 8px;

        @media(min-width: 992px) {
          font-size: 32px;
          font-weight: normal;
          line-height: 48px;
        }

      }
    
      .paragrafo {
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        color: black;
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: 10px;
        }
      }
    
      .number {
        color: #0045B5;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 24px;

        @media(min-width: 992px) {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 16px;
        }

        .texto-ddd{
          @media(min-width: 992px) {
            font-size: 24px;
          }
        }
      }
      .tab-whatsapp {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          background: #6CB136;
          color: white;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: center;
          text-decoration: none;
          padding: 14px;
          margin-bottom: 0;

          @media(min-width: 992px){
            max-width: 345px;
          }

          .icone{
            display: block;
            width: 21px;
            height: 21px;
            margin-right: 8px;
          }
      }

      .tab-contato {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background: #0045B5;
        color: white;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        text-decoration: none;
        padding: 14px;
        margin-bottom: 0;

        @media(min-width: 992px){
          max-width: 345px;
        }
    }
  }

  &.fade{
    .modal-dialog{
        transform: translate(0,50px);
    }
  }

  &.show{
    .modal-dialog{
        transform: translate(0,0);
    }
  }

}

.box-passos {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding-left: 0;
  background-color: #F2F2F3;
  list-style: none;
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: 992px) {
    background-color: transparent;
    margin-bottom: 32px;
  }

  &_passo{
    width: 100%;
    background-color: transparent;
    height: 18px;

    @media (min-width: 992px) {
      display: inline-flex;
      align-items: center;
      width: initial;
      height: auto;
      font-size: 14px;
      line-height: 18px;
      color: #797E86;
    }

    &:first-of-type{
      border-radius: 10px 0 0 10px;
    }

    &.active, &.feito {
      .box-passos_passo_nome::before {
        content: '\e917';
      }
    }

    &.active,
    &.feito {
      background-color: #0045B5;

      @media (min-width: 992px) {
        background-color: transparent;
      }     
    }

    &.active {
      .box-passos_passo_nome {
        border-color: #0045B5;
        color: #0045B5;
      }
      
      .box-passos_passo_nome::before {

        @media (min-width: 992px) {
          border-color: #0045B5;
          background-color: #0045B5;
          color: white;
        }        
      }
    }   

    &.feito{

      .box-passos_passo_nome {
        @media(min-width: 992px) {
          background-color: #0045B5;
          color: white;
          border-color: #0045B5;
        }
      }

      .box-passos_passo_nome::before{

        @media (min-width: 992px) {
          background-color: white;
          border-color: white;
          color: #0045B5;
        } 
      }
    }

    &_nome{
      display: none;
      align-items: center;
      margin-bottom: 0;
      padding: 14px 16px;
      border-radius: 8px;
      border: 1px solid #D1D3D4;

      @media (min-width: 992px) {
        display: inline-flex;
      }

      @media (min-width: 1200px) {
        padding: 14px 32px;
      }

      &::before{
        content: '';
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-family: 'icones-ipiranga-eventos' !important;
        font-size: 16px;
        color: #BEBFC1;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        font-size: 6px;
        border: 1px solid #939598;
        line-height: 1;
      }
    }

    & + .box-passos_passo{

      &::before {
        display: none;
        margin: 0 15px;
        font-family: 'icones-ipiranga-eventos' !important;
        font-size: 12px;
        color: #D1D3D4;

        @media (min-width: 992px) {
          content: '\e919';
          display: block;
        }
      }
    }
  }

  &_texto{
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 16px;
    color: #797E86;

    @media (min-width: 992px){
      display: none;
    }
  }
}

.barra-passos {
  margin-bottom: 36px;

  .texto-barra {
    color: #797E86;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .bar {
    border-radius: 15px;
    background:#F2F2F3;
    height: 18px;
    position: relative;
    width: 100%;
  }

  .bar-progress {
    background-color: #0045B5;
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    border-radius: 15px;
  }

  @media (min-width: 992px) {
    display: none;
  }
}

.inscricao {
  padding: 24px 0 40px;
  background-color: white;

  @media (min-width: 992px) {
    padding: 32px 0 75px;
    background-color: transparent;
  }

  .breadcrumb{
    margin-bottom: 32px;

    @media(min-width: 992px){
      margin-bottom: 24px;
    }
  }

  .infos {

    .titulo {
      color: #18191B;
  
      &.lg{
        margin-bottom: 16px;
  
        @media (min-width: 992px) {
          margin-bottom: 32px;
        }
      }
  
      &.md{
        margin-bottom: 16px;
  
        @media (min-width: 992px) {
          margin-bottom: 24px;
        }
      }
  
      &.sm{
        margin-top: 32px;
        margin-bottom: 16px;
      }
    }

    &-texto {
      display: none;

      @media (min-width:992px) {
        color:  #797E86;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 16px;
        display: block;
      }
    }
    
    .form-check {
      margin-bottom: 14px;

      .form-check-label {
        font-size: 14px;
      }
    }

    .link {
      transition: all ease-in-out .4s;
      color: #0045B5;
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      text-decoration-line: underline;

      &:hover {
        text-decoration: none;
        color: darken( #0045B5, 10%);
      }
    }
  }
  
  .btn-prox {
    margin-top: 24px;   

    @media (min-width: 992px) {
      margin-top: 56px;       
    }
  }

  .btn-voltar {
    margin-top: 16px;

    @media (min-width: 992px) {
      margin-top: 32px;
    }
  }

  
  &_rvv-2025{


    .c-black-200 {
      color: #000913 !important;
    }
    .c-cinza-200{
      color: #797E86 !important;
    }
      
   
    select{

      &:has(option:first-child:checked){
        color: #AAA;
      }
    }

  }
}

.sucesso-evento{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 80px;
  border-radius: 20px 20px 0 0;
  background-color: white;

  @media (min-width: 992px){
    align-items: flex-start;
    padding-top: 70px;
    padding-bottom: 130px;
    border-radius: 0;
  }

  .titulo.lg{
    @media (min-width: 992px){
      margin-bottom: 48px;
    } 
  }

  &_info{
    font-size: 18px;
    color: #797E86;
    text-align: center;

    @media (min-width: 992px){
      text-align: left;
    }

    .titulo{
      color: #18191B;
      font-weight: 400;
      margin-bottom: 24px; 
      
      &.xl-alt {
        @media(max-width: 991px){
          font-size: 32px;
        }
      }     
     
    }

    .texto{
      font-size: 16px;
      margin-bottom: 32px;

      @media(min-width: 992px){
        margin-bottom: 40px;
        font-size: 18px;
      }
    }

    .btn{
      margin-top: 16px;     
    }    
  }
}

.editar-sucesso {
  display: flex;
  align-items: center;
  padding: 40px 0 90px;

  @media (min-width: 992px) {
    height: calc(100vh - 164px);
    padding: 0
  }
}

.nowrap{
  white-space: nowrap;
}

.select-info-area{
  display: none;
}

.comunicacoes{
  @media (min-width: 992px) {
    padding-top: 44px;
  }

  @media (min-width: 1200px) {
    padding-top: 54px;
  }
}

.atualizar-dados-sucesso {
  min-height: calc(100vh - 65px);
  padding: 24px 0;

  @media (min-width: 992px) {
    padding: 33px 0;
  }

  &.height{
    @media (min-width: 992px){
     min-height: calc(100vh - 164px);
    }
  }
  .onboarding_card {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 16px;

    @media(min-width: 992px) {
      flex-direction: row;
      align-items: center;
      margin-top: 52px;
      padding-bottom: 40px !important;
    }

    .image {
      display: block;
      max-width: 272px;
      margin: 0 auto 8px;

      @media(min-width: 992px) {
        max-width: initial;
        margin: 0 26px 0 0;
      }
    }

    .content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;

      .info {
        margin-bottom: 24px;      

        .title {
          color: #18191B;
          text-align: center;
          font-family: 'ipiranga-titulos', sans-serif;
          font-size: 32px;
          line-height: normal;
          margin-bottom: 16px;

          @media(min-width: 992px) {
            text-align: initial;
            font-size: 48px;           
            line-height: 48px;
          }
        }

        .desc {
          color: #797E86;
          text-align: center;
          font-size: 16px;
          line-height: normal;
          margin-bottom: 0;

          @media(min-width: 992px) {
            text-align: initial;
            font-size: 18px;
          }
        }
      }

      .btn {
        display: inline-flex;
        width: 100%;

        @media(min-width: 992px) {
          width: initial;
          min-width: 200px;
        }
      }
    }
  }

  &--v2{
    min-height: inherit;
    font-size: 16px;
    color: #797E86;

    @media (min-width: 992px) {
      min-height: 400px;
      height: calc(100vh - 165px);
      font-size: 18px;
    }

    .container,
    .row{
      height: 100%;
    }

    .titulo{
      margin-top: 24px;
      margin-bottom: 24px;
      color: #18191B;

      @media (min-width: 992px) {
        margin-top: 0;
        margin-bottom: 32px;
      }
    }

    .btn{
      margin-top: 24px;

      @media (min-width: 992px) {
        margin-top: 32px;
      }
    }
  }
}

.seja-bem-vindo{
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding-top: 32px;

  @media (min-width: 992px){
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .onboarding_card {

    .image{
      max-width: 100%;

      @media(min-width: 992px) {
        max-width: initial;
      }
    }

    .desc{color: #18191B !important;}
  }
}

.faq-paginacao{
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
  margin-bottom: 0;
  padding-left: 0;
  color: #D1D3D4;
  font-size: 18px;
  font-weight: 500;
  list-style: none;

  &_btn{
    padding: 0;
    color: inherit;
    background-color: transparent;
    border: none;
    transition: all ease 0.4s;

    &:hover{
      text-decoration: underline;
    }

    &.active{
      color: #0045B5;
      text-decoration: underline;
    }
  }
}

.error-sect {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 164px);


    .titulo-error {
      color: #18191B;
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px;
      margin-bottom: 32px;
    }

    .texto-error {
      color: #797E86;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 40px;
      max-width: 551px;
    }

    .btn-azul {
      width: 263px;
    }
  }
}

.recuperar-senha-btn{
  margin-bottom: 32px;
  color:  #0045B5;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  display: block;
  text-align: end;
  transition: all ease 0.4s;

  &:hover{
    text-decoration: underline;
  }
  
  @media (min-width: 1200px) {
    font-size: 14px;
    margin-bottom: 16px;
  }

  @media (min-width: 1400px) {
    font-size: 16px;
    margin-bottom: 32px;
  }
  
}

.nav-meu-perfil{
  margin-top: 32px;
  margin-bottom: 0;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  overflow: auto;

  .nav-content{
    width: max-content;
    display: flex;
    align-items: center;


    .nav-item{
      padding: 8px 16px;
      color: #1D1E1E;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      border-bottom: 2px solid;
      border-color: #E0E0E0;
      text-decoration: none;
      transition: all .3s ease;

       &:hover{
        border-color: #B7B7B7;
       }

       &.active{
        color: #0045B5;
        border-color: #0045B5;
        font-weight: 700;
       }
    }
  }
}

.mapa{
  flex: 1;
  margin-left: -20px;
}

.mapa-rvv {
  @media(max-width: 767px){
    margin-left: 0;
    height: 196px;
    margin-bottom: 24px;
  }
}

.mapa-mobile {
  @media(max-width: 992px){
    margin-left: 0;
    height: 200px;
    margin-bottom: 24px;
  }
}

.sucesso_wrapper {
  @media(min-width: 992px) {
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }
}

.sucesso-evento {
  &.min-height-control {
    @media(max-width: 767px){
      min-height: calc(100vh - 86px);
    }
  }
}

.min-height-control {
  min-height: calc(100vh - 285px);

  @media(min-width: 992px) {
    min-height: calc(100vh - 341.78px);
  }
}

@keyframes slide-fade-in-bounced {
  60% {
    opacity: 1;
    transform: translateX(-10px);
  }
  100% { 
    opacity: 1;
    transform: translateX(0);
  }
}

.completar-inscricao_float {
  display: none;

  @media(min-width: 992px){
    position: absolute;
    right: 12px;
    bottom: -56px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 16px 16px 26px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 7px 15px 0px rgba(0, 0, 0, 0.10), 0px 28px 28px 0px rgba(0, 0, 0, 0.09), 0px 63px 38px 0px rgba(0, 0, 0, 0.05), 0px 113px 45px 0px rgba(0, 0, 0, 0.01), 0px 176px 49px 0px rgba(0, 0, 0, 0.00);
    opacity: 0;
    transform: translateX(100%);
    animation: slide-fade-in-bounced 0.8s 0.4s ease forwards;    
  }

  .image_holder {
    position: relative;

    .indicator {
      position: absolute;
      top: 3px;
      left: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #F04C25;
      color: var(--Branco, #FFF);   
      font-size: 15px;  
      font-weight: 700;
      line-height: normal;
    }
  }

  .content {
    .desc {
      color: #000;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 18px;      
      font-weight: 700;
      line-height: normal;
      margin-bottom: 8px;
    }

    .link {
      text-decoration: none;
      font-weight: 500;
      color: #0045B5;  

      &:hover {
        .icones {
          transform: translateX(5px);
        }
      }

      .link_text {       
        font-size: 16px;     
        line-height: normal;
      }

      .icones {
        display: inline-block;
        font-size: 12px;
        margin-left: 8px;
        transition: 0.3s ease;
      }
    }
  }
}

.steps_wrapper {
  margin-bottom: 40px;

  .mobile-step_indicator {
    display: flex;
    align-items: center;
    justify-content: space-between;    
    margin-bottom: 13px;

    @media(min-width: 992px) {
      display: none;
    }

    .content_holder {
      display: flex;
      align-items: center;
      gap: 10px;

      .step_indicator {
        background-color: #0045B5;
        color: white;
      }
  
      .step_name {
        color: #0045B5;
      }
    }   

    .steps_counter {
      color: #AFB2B7;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 16px;    
      font-weight: 700;
      line-height: 18px;
    }
  }

  .mobile-indicator_bar {
    width: 100%;
    border-radius: 21px;
    height: 10px;
    background-color: #CCDAF0;
    overflow: hidden;

    @media(min-width: 992px) {
      display: none;
    }

    .bar {
      height: 10px;
      background-color: #0045B5;
      border-radius: 21px;
    }
  }

  .step_indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    min-width: 17px;
    min-height: 17px;
    height: 17px;
    border-radius: 50%;     
    background-color: #99B5E1;
    color: white;

    &_number {
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 12px;
      font-weight: 700; 
      line-height: normal; 
      margin-bottom: 0;
    }

    .icone-check-small {
      font-size: 6px;
    }
  }

  .step_name {   
    font-family: 'ipiranga-titulos', sans-serif;
    font-size: 16px; 
    font-weight: 700;
    line-height: 18px;
    color: #99B5E1;
  }

  .steps_list {   
    display: none;   
    margin-bottom: 0;

    @media(min-width: 992px) {
      display: flex;
    }

    .step_content {        

      @media(min-width: 992px){
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 13px;
      }     
    }

    .step_bar {
      width: 100%;
      height: 10px;
      border-radius: 21px;
      background-color: #99B5E1;
    }

    .current {
      .step_indicator {
        background-color: #0045B5;
      }

      .step_name {
        color: #0045B5;
      }

      .step_bar {
        background-color: #0045B5;
      }
    }

    .done {
      .step_indicator {
        background-color: #6CB136;
      }

      .step_name {
        color: #6CB136;
      }

      .step_bar {
        background-color: #6CB136;
      }
    }
  }
}

.text-reenviar{
  color:  #797E86;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;

}

.c-verde {
  color: #00551D;
}

.seg {
  color: $azul;
}

.novidades{
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
  overflow: hidden;

  @media (min-width: 992px){
      padding-top: 80px;
      padding-bottom: 120px;
      background-color: #F4F4F5;
  }

  .container{
    position: relative;
    z-index: 2;
  }

  .card-wrapper{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 24px;

      @media (min-width: 992px){
          flex-direction: row;
      }

      .card-novidade {
        @media(min-width: 992px){
          width: calc(25% - 18px);
          max-width: calc(25% - 18px);
          min-width: calc(25% - 18px);
        }
      }  
  }

  &_header{
      margin-bottom: 18px;

      @media (min-width: 992px){
          display: flex;
          justify-content: space-between;
          margin-bottom: 32px;
      }

      .titulo{
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 0;

        @media (min-width: 768px){
            font-size: 32px;
        }

        @media (min-width: 1200px){
            font-size: 48px;
            line-height: 48px;
            font-weight: 400;
        }
      }

      .link-underline{
          display: none;

          @media (min-width: 992px){
              display: inline-block;
          }
      }
  }

  .btn-ver-mais{
      width: 100%;
      margin-top: 24px;
  }

  .triangulo-amarelo,
  .triangulo-laranja,
  .triangulo-azul{
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    translate: 50% 50%;

    @media (min-width: 992px){
      display: block;
    }
  }

  .triangulo-amarelo[data-in="true"]{
    translate: 0 0;
    animation: triangulo-amarelo-in ease 1s;
  }

  .triangulo-laranja[data-in="true"]{
    translate: 0 0;
    animation: triangulo-laranja-in ease 1s;
  }

  .triangulo-azul[data-in="true"]{
    translate: 0 0;
    animation: triangulo-azul-in ease 1s;
  }

  &.rvv {
    background-color: #0045B5;
  }
}

.novidades-index {
    padding: 0;

    @media (min-width: 992px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@keyframes triangulo-amarelo-in {
  0%{
    translate: 50% 50%;
  }
  100%{
    translate: 0 0;
  }
}

@keyframes triangulo-laranja-in {
  0%{
    translate: 50% 50%;
  }
  20%{
    translate: 50% 50%;
  }
  100%{
    translate: 0 0;
  }
}
@keyframes triangulo-azul-in {
  0%{
    translate: 50% 50%;
  }
  30%{
    translate: 50% 50%;
  }
  100%{
    translate: 0 0;
  }
}

.materia-sect_holder {
  padding: 24px 0 80px; 

  @media (max-width: 767px){
    min-height: calc(100dvh - 86px - 218px);
    padding-top: 16px;
  }
  
  .materia_intro {  
    padding-bottom: 24px;    

    @media(min-width: 992px){
      border-bottom: 1px solid #DCDCDC;
      margin-bottom: 24px;
    }

    .intro_title {
      color: #18191B;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 32px;  
      line-height: normal;
      margin-bottom: 16px;

      @media(min-width: 992px){
        font-size: 48px; 
      }
    }

    .intro_text {
      color: #303236; 
      font-size: 16px; 
      line-height: normal;
      margin-bottom: 16px;
    }

    .intro_date {
      color: #939598;  
      font-size: 12px;     
      line-height: normal;
    }    
  }

  .materia_image {
    width: 100%;
    max-width: 100%;
  }

  .materia_content {
    margin: 32px 0 64px;
    color: #303236;

    @media(min-width: 992px){
      margin: 48px 0 80px;
    }

    p {
      font-size: 16px;     
      line-height: normal;
      margin-bottom: 24px;
    }

    h3 {
      font-size: 24px;    
      font-weight: 700;
      margin-bottom: 24px;
    }

    h4 {
      font-size: 18px;    
      font-weight: 700;
      margin-bottom: 24px;
    }

    h5 {
      font-size: 14px;
      font-weight: 500;
      line-height: 120%;
    }

    ul {
      margin-bottom: 24px;

      li {
        font-size: 16px;
        line-height: normal;

        + li {
          margin-top: 20px;
        }
      }
    }
  }

  .arquivo-holder {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: fit-content;
    gap: 24px;

    margin-bottom: 120px;
  }

  .card-novidade {
   height: 100%;
  }


  .seja-bem-vindo{
    color: black;

    .intro_text{
      font-size: 18px;
      color: #61656B;
  
      @media (min-width:992px) {
        font-size: 22px;
      }
    }

    .titulo-secao{
      color: #303236;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 32px;

      @media (min-width:992px) {
        font-size: 32px;
      }
    }
    .titulo-destaque{
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 16px;

      @media (min-width:992px) {
        font-size: 24px;
        margin-bottom: 24px;
      }
    }
    .img-destaque{
      border-radius: 20px;
      margin-bottom: 24px;
      width: 100%;

      @media (min-width: 992px) {
        margin-bottom: 0;
      }
    }
  }
}

.mb-24-32{
  margin-bottom: 24px;

  @media (min-width: 992px) {
    margin-bottom: 32px;
  }
}

.voltar-padrao {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #0045B5;
  text-decoration: none;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}

.anteriores-segmentos {
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 32px;

  @media(min-width: 992px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .infoWrapper{

    @media(min-width: 992px){
      padding-right: 20px;
      text-align: left;
    }
  }

  .titulo {
    position: relative;
    z-index: 2;
    margin-bottom: 40px;
    font-size: 32px;
    line-height: normal;

    @media(min-width: 992px){
      font-size: 45px;
      margin-bottom: 8px;
    }
  }

  .texto {
    color: #303236;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

  }

  .swiper{        
    height: 100%;
    overflow: visible;
    margin-left: 0;

    .swiper-wrapper {
      .swiper-slide {
        width: 340px;
      }
    }
  }

  .navigation{
    display: flex;
    width: 180px;
    position: relative;
    bottom: -40px;
    left: 0;
    z-index: 1;

    @media(min-width: 992px){
      width: 136px;
      transform: none;
      bottom: 35px;
      left: -157px;
    }
  }

  .swiper-button-next {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #0045B5;
    color: white;
    font-size: 18px;
    transition: 0.3s ease;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
    &::after{display: none;}

    &.swiper-button-disabled{
      background-color: #E9EAEA;
      opacity: 1;
    }
  }

  .swiper-button-prev {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #0045B5;
    color: white;
    font-size: 18px;
    transition: 0.3s ease;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
    &::after{display: none;}

    &.swiper-button-disabled{
      background-color: #E9EAEA;
      opacity: 1;
    }
  }

  .swiper-pagination{
     display: block;
     bottom: -15px;
     color: #0045B5;
     font-size: 18px;
     font-weight: 700;
     line-height: normal;
     z-index: -1;

     @media(min-width: 992px) {
        display: none;
     }
  }
}

.card-anteriores {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  outline: none;
  padding: 0;
  text-decoration: none;
  transition: .2s ease-in;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.08), 0px 2px 5px 0px rgba(0, 0, 0, 0.08), 0px 10px 10px 0px rgba(0, 0, 0, 0.07), 0px 6px 40px 0px rgba(0, 0, 0, 0.04), 0px 40px 16px 0px rgba(0, 0, 0, 0.01), 0px 62px 17px 0px rgba(0, 0, 0, 0.00);

  &.off{

    @media(min-width: 992px){
      transform: scale(0.3);
      opacity: 0;
      z-index: -1;
    }
  }

  .image_holder {
    position: relative;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
      content: '';
      display: block;
      padding-bottom: 83%;
    }
  }

  .card_content {
    background-color: white;
    padding: 24px 32px 40px 32px;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;

    .content_holder {
      flex: 1;      
    }

    .info_holder {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;
    }   

    .title {
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 8px;
      color: #0E1A2A;
      line-height: 32px;
    }

    .box {
      border-radius: 4px;
      background-color: #F4F4F5;
      padding: 10px 16px;
      color: #303236;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;     
      display: flex;
      align-items: center;   
      gap: 8px;  
    }

    .link-card {
      color: #0045B5;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      display: flex;
      align-items: center;
      width: fit-content;
      transition: all ease-in-out .4s;
      margin-top: 32px;

      &:hover {
        color: darken(#0045b5, 10%);
      }
    }

    .icone-seta-esquerda {
      transform: rotate(180deg);
      display: inline-flex;
      margin-left: 8px;
    }
  }

}

.evt-anteriores {
  @media (min-width: 992px) {
    display: none;
  }

  .titulo {
    color: #18191B;
    font-family: 'ipiranga-titulos', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
  }

  &_ul {
    margin-top: 0;
    margin-bottom: 28px;
    padding: 0;

    @media (min-width: 768px){
        margin-top: 32px;
        margin-bottom: 24px;
    }
  }

  &_li {
    list-style: none;
    padding-top: 12px;

    &:hover, &:active, &:focus {
      background-color: #F4F4F5;
    }

    &:last-of-type {
      margin-bottom: 0;

      .link{
        border-bottom: none;
      }
    }
    

    .link {
      color: #18191B;
      font-family: 'ipiranga-titulos', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      // border-bottom: 1px solid #D1D3D4;
      padding-bottom: 12px;

      .link_info {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .link_data{
          font-family: 'ipiranga-titulos', sans-serif;
          color: #58595B;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          margin-bottom: 0;
        }
      }
    }

    .icone-chevron-right {
      color: #0045B5;
      display: inline-flex;
      align-items: center;
    }
  }   

  .btn {
    width: 100%;
  }
}

.section-banner{
  padding-top: 24px;

  @media(min-width: 992px){
    padding-top: 0;
  }

  &:not(.swiper-pagination){
    padding-bottom: 0;
  }
  &:has(.swiper-pagination){
    padding-bottom: 32px;
  }
}

.banner-carousel{

  .swiper-slide {
    height: auto;

    .container {    

      @media(max-width: 991px){
        height: 100%;
      }
    }
  }

  .banner-item{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;

    @media(min-width: 992px){
      border-radius: 0;
      height: 550px;
    }   

    .img-desk{
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right center;
      z-index: -1;

      @media(min-width: 992px){
        display: block;
      }
    }

    .center {
      @media (min-width: 992px) {
        background-position: center;
      }
    }

    .img-desk-rodo-rede {
      @media (min-width: 992px) {
        background-position: center;
      }
    }

    .img-mobile{
      width: 100%;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      aspect-ratio: 9/9;

      @media(min-width: 600px){
        aspect-ratio: 8/8;
      }
      @media(min-width: 768px){
        aspect-ratio: 10/8;
      }
      @media(min-width: 992px){
        display: none;
      }
    }

    &:has(.img-mobile-v2){
      height: 550px;

      @media(max-width: 991px){
        height: 100%;

        .container{
          padding: 0;
          max-width: 100%;
        }
      }      
    }

    .img-mobile-v2 {
      display: flex;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 400px;
      border-radius:0;

      @media (min-width: 992px) {
        display: none;
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;     
        background-color: transparent;

        @media (min-width: 992px) {
          display: none;
        }

        .btn-azul-claro-100 {
          display: flex;
          width: fit-content;
          color: white;
          padding: 0 32px;
          font-size: 14px;
          height: 43px;
          min-height: 43px;
        }
      }
    }

    .info{
      color: black;
      padding: 16px;
      background-color: #F5F5F5;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px; 

      @media(max-width: 991px){
        height: calc(100% - 492px);
      }

      @media(min-width: 992px){
        color: white;
        padding: 0;
        background-color: transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &-title{
          font-family: 'ipiranga-titulos', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 16px;

          @media(min-width: 992px) {
            font-size: 56px;
            font-weight: 700;
            line-height: 110%; 
          }
      }
      &-paragrafo{
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        margin-bottom: 16px;
        display: none;

        @media(min-width: 992px){
          margin-bottom: 20px;
          display: block;
        }
      }
      &-detalhes{
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;
 
        @media(min-width: 992px){
          gap: 20px;
          margin-bottom: 32px;
        }

        .data,.local {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%;
          margin-bottom: 0;
          background-color: #E5E6E7;
          border-radius: 6px;
          padding: 10px 16px;
          text-wrap: nowrap;

          @media(max-width: 992px){padding: 10px;}

          @media(min-width: 992px){
            font-size: 16px;
            font-weight: 500;
            gap: 14px;
            border-radius: 0;
            padding: 0;
            background-color: transparent;
            text-wrap: unset;
          }
        }

        .local {
          svg{
          
            @media(min-width: 992px){
              width: 24px;
              height: 24px;
            }
            path{

              @media(min-width: 992px){
                fill: #FFD100;
              }
            }
          }
        }

        .icones{
          color: black;
          font-size: 18px;

          @media(min-width: 992px){
              color: #FFD100;
              font-size: 24px;
          }
        }
      }

      .btn{
        width: 100%;

        @media(min-width: 992px){
          max-width: 300px;
        }
      }

      .info-title-v2 {
        color: #FFF;
        text-align: center;
        font-family: 'ipiranga-titulos', sans-serif;
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 16px;
  
        @media (min-width: 992px) {
          font-size: 48px;
        }
  
      }
  
      .info-texto {
        color: #FFF;
        text-align: center;
        font-family: 'ipiranga-titulos', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 24px;
        max-width: 305px;
      }

      .icone-sino {
        font-size: 18px;
      }
    }

    .info-v2 {
      display: none;
      
      @media (min-width: 992px) {
        display: flex;
        align-items: center;
        flex-direction: column;
  
        .btn-azul-claro-100 {
          font-size: 14px;
          padding: 0 32px;
          height: 43px;
          max-width: 238px;

          &:hover {
            .icone-sino {
              animation: vibrar 0.3s ease infinite;
            }
          }
        }

        @keyframes vibrar {
          0%, 100% {
            transform: translateX(0);
          }
          25% {
            transform: translateX(-3px) rotate(8deg);
          }
          50% {
            transform: translateX(3px) rotate(-8deg);
          }
          75% {
            transform: translateX(-3px) rotate(8deg);
          }
        }
      }
    }

    &.dark-colors {
      .info {
        color: #000000;

        .info-detalhes {
          .icones {
            color: #0045B5;
          }
        }
      }
    }

    &.banner-bem-vindo{
      
      .img-mobile{
         aspect-ratio: 8/6.2;

         @media(min-width: 440px){
          aspect-ratio: 8/7;
         }

        @media(min-width: 600px){
         aspect-ratio: 8/7.3;
        }

        @media(min-width: 768px){
          aspect-ratio: 10/7.3;
         }
        
         @media(min-width: 992px){
          aspect-ratio: 10/8;
         }
      }
      .info{

        @media(max-width: 390px){
          .info-title{font-size: 23px;}
        }
        
        @media(min-width: 992px){
          max-width: 455px;
        }

        .btn{
          max-width: 100%;
        }

        .info-title{
          margin-bottom: 8px;

          @media(min-width: 992px){
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 16px;
          }
        }
        .info-paragrafo{
          margin-bottom: 16px;

          @media(min-width: 992px){
            margin-bottom: 32px;
          }
        }
      }
    }
  }

  .ml-especial {
    @media (min-width: 1200px) {
      margin-left: 50px;
    }

    @media (min-width: 1280px) {
      margin-left: 0;
    }

    @media (min-width: 1400px) {
      margin-left: 50px;
    }

    @media (min-width: 1480px) {
      margin-left: 0;
    }
  }

  .swiper-wrapper{
    @media(max-width: 991px){
      padding-bottom: 40px;
    }
  }
  
  .swiper-pagination{
    bottom: 0;
  
    @media(min-width: 992px){
      bottom: 24px;
    }
  
    .swiper-pagination-bullet{
      background-color: #E9EAEA;
      width: 10px;
      height: 10px;
      opacity: 0.5;
      border-radius: 10px;
  
      @media(min-width: 992px){
        background-color: white;
        width: 16px;
        height: 12px;
        border-radius: 10px;
      }
    }
  
    .swiper-pagination-bullet-active {
      width: 10px;
      height: 10px;
      background-color: #0045B5;
      opacity: 1;
  
      @media(min-width: 992px){
        background-color: white;
        width: 30px;
        height: 12px;
      }
    }
  }

  .navigation{
    display: none;

    @media(min-width: 1200px){
      display: flex;
      width: 136px;
      transform: none;
      bottom: 35px;
      left: -157px;
    }
  }

  .swiper-button-next {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    font-size: 16px;
    transition: 0.3s ease;
    cursor: pointer;
    right: 24px;

    &:hover {
      opacity: .8;
    }

    &::after{display: none;}

    &.swiper-button-disabled{
      opacity: 0;
    }
  }

  .swiper-button-prev {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    font-size: 16px;
    transition: 0.3s ease;
    cursor: pointer;
    left: 24px;

    &:hover {
      opacity: 0.8;
    }

    &::after{display: none;}

    &.swiper-button-disabled{
      opacity: 0;
    }
  }


    &:has(.img-mobile-v2) {
      @media(max-width: 991px) {
        .swiper-wrapper {
          padding-bottom: 0;
        }
      }
    }

}

.parceiros_sect {
  overflow: hidden;
  display: flex;
  align-items: center;
  background-image: url('../img/bg-parceiro-mobile.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 404px;

  @media(min-width: 992px){
    background-image: url('../img/bg-parceiro.svg');
    height: 810px;
  }

  .parceiros_carousel {
    overflow: visible;

    @media(min-width: 992px){
      overflow: hidden;
      padding-top: 8px;
    }

    &.desktop-padding {

      @media(min-width: 992px){
        padding: 8px;
      }  
    }

    .swiper-wrapper {
      flex-direction: row !important;

      .swiper-slide {
        margin-top: 0 !important;
        margin-bottom: 24px !important;
        width: 156px;

        @media(min-width: 992px){
          width: initial;
        }
      }
    }   
    
    .swiper-pagination {
      display: none;
      position: relative;
      bottom: -6px;

      @media(min-width: 992px){
        display: block;
      }

      &-bullet {
        width: 15px;
        height: 15px;
        background-color: white;
        opacity: 1;

        &-active {
          background-color: #FFD100;
        }
      }
    }
  }

  .title {
    color: #EBF2FF;
    font-family: 'ipiranga-titulos', sans-serif;
    font-size: 24px;  
    line-height: normal;
    margin-bottom: 24px;

    @media(min-width: 992px){
      font-size: 48px;
      margin-bottom: 32px;
    }
  }

  .parceiro_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;  
    background-color: white;
    padding: 18px 16px 24px;
    border-radius: 16px;
    text-decoration: none;

    @media(min-width: 992px){
      padding: 22px 32px 32px;     
      
      transition: 0.3s ease;

      &:hover {
        transform: translateY(-8px);
      }
    }

    .logo_holder {
      position: relative;
      width: 100%;

      margin-bottom: 16px;

      @media(min-width: 992px){ 
        margin-bottom: 22px;
      }

      &::after {
        content: '';
        display: block;
        padding-bottom: 57.5%;
      }
    }

    .logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;    
    }

    .parceiro_name {
      color: #131313;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;  
      font-weight: 500;
      line-height: 120%; 
      margin-bottom: 16px;
    }

    .link {
      color: #0045B5;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-size: 12px;  
      line-height: 120%;
      text-decoration-line: underline;
      text-transform: lowercase;      
    }

    &.selo-desconto{
        position: relative;

     &::after{
      content: '';
      position: absolute;
      top: -4px;
      right: -1px;
      width: 32px;
      height: 32px;
      background-image: url(../img/icone-selo-desconto.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;


      @media (min-width: 992px){
        top: -8px;
        right: -6px;
        width: 48px;
        height: 48px;
      }
     }
    }
  }

  &--v2{
    margin-top: 24px;
    background-image: none;
    height: auto;

    @media (min-width: 992px){
      margin-top: 0;
    }

    .title{
      color: #18191B;
    }

    .parceiro_card{
      border: 3px solid #F2F2F3;

      .logo{
        @media (min-width: 992px){
          margin-bottom: 24px;
        }
      }

      .parceiro_name{

        @media (min-width: 992px){
          margin-bottom: 24px;
          font-size: 24px;
        }
      }
    }
  }

  .parceiros-interna{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 16px;
    padding-bottom: 40px;

    @media (min-width: 768px){
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px 24px;
    }

    @media (min-width: 1200px){
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-bottom: 64px;
    }
  }

}

.novidades_title {
  color: #000;
  font-family: 'ipiranga-titulos', sans-serif;
  font-size: 48px;
  line-height: normal;
  margin-bottom: 32px;
}

.mobile-full {
  @media(max-width: 991px){
    width: 100%;
  }
}

.faq-sect_holder {
  padding: 24px 0 80px; 
  // overflow: hidden;

  .section-faq {
    padding-top: 0;

    @media (min-width: 992px) {
      margin-bottom: 51px;
    }

    .titulo-faq {
      display: none;
      @media (min-width: 992px) {
        margin-bottom: 32px;
        display: block;
      }
    }

    .filtro {
      @media (min-width: 992px) {
        margin-bottom: 24px;
      }
    }

    .accordion-faq {
      .accordion-button {
        padding: 17px 17px 17px 48px;
  
        @media (min-width: 992px) {
          padding: 25px 56px;
        }

      }

    }

    .accordion-body {
      padding: 0  16px 16px 48px;

      @media (min-width: 992px) {
        padding: 0  24px 24px 56px;
      }

      p {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
    
    .faq-paginacao {
      margin-top: 24px;

      @media (min-width: 992px) {
        margin-top: 32px;
      }
    }
  }
}

.mobile-padding {
  padding-bottom: 80px;

  @media(min-width: 992px){
    display: none;
  }
}

.palestrantes_sect {
  overflow: hidden;

  @media(min-width: 768px){
    display: none;
    align-items: center;   
    background-image: url('../img/bg-palestrante.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 0;
  }

  &.loaded {
    @media(min-width: 768px){
      display: flex;
      height: 810px;
    }
  }
  

  .title {
    color: #FFF;
    font-family: 'ipiranga-titulos', sans-serif;
    font-size: 48px;
    line-height: normal;
    margin-bottom: 16px;
  }

  .nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
    border: 0;
    margin: 0 -24px 32px;
    padding: 0 24px;
    overflow: auto;
    
    @media(min-width: 768px){
      margin: 0 0 32px;
      padding: 0;
      overflow: visible;
    }

    .nav-link {
      text-align: center;
      padding: 8px 24px;
      border-radius: 6px;
      color: #0045B5;
      font-size: 16px;   
      font-weight: 500;
      line-height: normal;
      border: 1.5px solid #0045B5;
      white-space: nowrap;

      @media(min-width: 768px){
        padding: 16px 24px;
        background-color: #0045B5;
        border: 2px solid #0045B5;
        color: white;
      }

      &.active {
        color: white;
        background-color: #0045B5;

        @media(min-width: 768px){
          background-color: white;
          color: #0045B5;
        }
      }
    }
  }

  @keyframes o-rotate-360 {
    0% { transform: rotate(0) }
  
    100% { transform: rotate(360deg) }
  }

  .palestrantes_carousel {
    overflow: visible;

    .btns_wrapper {
      display: none;
      align-items: center;
      gap: 16px;
      margin-top: 32px;

      @media(min-width: 768px){
        display: flex;
      }

      .swiper-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        outline: none;
        border: 0;
        position: relative;
        bottom: initial;
        top: initial;
        right: initial;
        left: initial;
        margin-top: 0;
        width: 52px;
        min-width: 52px;
        height: 52px;
        border-radius: 50%;
        background-color: #FF6900;

        &-disabled {
          background-color: rgba(255, 255, 255, 0.2);
          opacity: 1;

          .icon {
            opacity: 0.2;
          }
        }

        &::after {
          display: none;
        }
      }
    }

    .swiper-wrapper {
      @media(max-width: 767px){
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
      }
    }

    .swiper-slide {     
      height: auto;

      @media(max-width: 767px){
        width: calc(50% - 8px);
      }      
    }

    .card_holder {
      width: 100%;
      position: relative;
      overflow: hidden;      
      border-radius: 16px;
      padding: 0;  
      transition: 0.3s ease; 
      height: 100%;

      @media(min-width: 768px){
        padding: 3px;
        
      }

      &.off{

        @media(min-width: 768px){
          transform: scale(0.3);
          opacity: 0;
          z-index: -1;
        }
      }

      &:hover {
        .photo_holder {
          .photo {
            transform: scale(1.1);
          }         
        }
        .border-colored {       
          span {        
            &:first-child {
              background: #FF4126;

              &:after { background: #FF4126;
                }
            }
        
            &:last-child {
              background: #FF4126;

              &:after { background: #FFD100;
                 }
            }
          }
        }
      }

      .border-colored {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;        
        animation: o-rotate-360 linear 5s infinite;

        @media(min-width: 768px){
          display: block;
        }
      
        span {
          display: block;
          width: 100%; height: 100%;
          position: relative;
          transform: translate(-50%, -50%);
      
          &:after {
            display: block;
            content: "";
            width: 100%; height: 100%;
            position: absolute;
            left: 100%;
          }
      
          &:first-child {
                      background: transparent;
            &:after { background: transparent }
          }
      
          &:last-child {
                      background: transparent;
            &:after { background: transparent }
          }
        }
      }

      .palestrante_card {  
        position: relative;       
        z-index: 2;
        display: block;   
        overflow: hidden;
        text-decoration: none;
        border-radius: 16px;
        border: 1px solid #E9EAEA;
        height: 100%;  
  
        @media(min-width: 768px){           
          border-color: transparent;          
        }    
  
        .photo_holder {
          position: relative;
          width: 100%;          
          overflow: hidden;
          background-color: #f2f2f3;
  
          &::after {
            content: '';
            display: block;
            padding-bottom: 117%;         
          }

          .photo {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            transition: 0.3s ease;
          }

          &-texto{
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: white;
            background-color:  #FF8B00;
            padding: 10px 16px;
            font-size: 14px;
            font-weight: 500;
            line-height: 110%;
            margin-bottom: 0;
          }
        }
  
        .content {   
          display: flex;
          flex-direction: column;       
          background-color: white;
          padding: 16px 16px 24px;
          height: 100%;
  
          @media(min-width: 768px){
            display: block;
            padding: 22px 24px 22px;
          }
  
          .palestrante_name {
            color: #18191B;
            font-family: 'ipiranga-titulos', sans-serif;
            font-size: 16px;        
            font-weight: 700;
            line-height: normal;
            margin-bottom: 4px;
  
            @media(min-width: 768px){
              font-size: 24px;
            }
          }
  
          .palestrante_role {
            color: #939598;  
            font-size: 12px;      
            font-weight: 400;          
            line-height: normal;
  
            @media(min-width: 768px){
              font-size: 16px;      
              font-weight: 500;
            }
          }
        }
      }
    }   
  }
}

.palestrante-interna_sect {
  padding-bottom: 100px;

  @media(min-width: 768px){
    min-height: calc(100vh - 338px);
    padding-top: 31px;
  }
  .container {
    
    .content_wrapper {
      @media(min-width: 992px){
        display: flex;
        align-items: flex-start;
        gap: 40px;
      }

      .logo_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 10px 60px;

        @media(min-width: 992px){
          padding: 0;
          width: 264px;
          min-width: 264px;
        }

        .logo {
          max-width: 100%;
        }
      }

      .photo_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;        
        position: relative;
        width: 100%;
        margin-bottom: 16px;    
        overflow: hidden;   

        @media(min-width: 992px){
          margin-bottom: 0;
          padding-left: 100px;
          width: 360px;   
          min-width: 360px;   
          overflow: visible;  
          margin-top: 31px;   
        }

        .mobile-image_background {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background-position: bottom center;
          background-repeat: no-repeat;
          background-size: cover;
          filter: blur(15px);

          @media(min-width: 992px){
            display: none;
          }
        }

        .triangulo {
          display: none;
          position: absolute;

          @media(min-width: 992px){
            display: block;
          }

          &.amarelo {
            z-index: 2;
            left: 0;
            top: -30px;
          }

          &.laranja {
            left: 45px;
            bottom: -45px;
          }
        }

        .photo_holder {
          position: relative;
          z-index: 3;          
          max-width: 220px;

          @media(min-width: 992px){
            max-width: initial;
            border-radius: 16px;
            overflow: hidden;
          }
        }
      }

      .content {
        padding: 0 24px; 

        @media(min-width: 992px){
          padding: 0;              
        }

        @media(min-width: 1200px){
          padding-right: 100px;              
        }

        .title {
          color: #000;
          font-family: 'ipiranga-titulos', sans-serif;
          font-size: 24px;       
          font-weight: 700;
          line-height: normal;
          margin-bottom: 8px;

          @media(min-width: 768px){
            font-size: 48px;
          }
        }

        .role {
          color: #58595B;     
          font-size: 16px;      
          font-weight: 300;
          line-height: normal;
          margin-bottom: 16px;

          @media(min-width: 768px){
            font-size: 24px;
          }
        }

        .social-medias_wrapper {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-bottom: 24px;

          @media(min-width: 992px){
            margin-bottom: 32px;
          }

          .social-media_link {
            transition: 0.3s ease;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .btn-wrapper {
          .btn-azul {
            width: 100%;
            margin-bottom: 16px;

            @media (min-width: 992px) {
              width: 293px;
              margin-bottom: 24px;
            }
          }
        }

        .desc {
          color: #18191B;        
          font-size: 14px;      
          line-height: 140%;
          margin-bottom: 0; 

          @media(min-width: 768px){
            font-size: 16px;
          }

          + .desc {
            margin-top: 16px;

            @media(min-width: 768px){
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}

.container-desktop-only {
  @media(max-width: 991px){
    max-width: initial;
    padding: initial;
  }
}

#confetti {
  position: relative;
  z-index: 2000;
}

.sect-breadcrumb{
  padding: 20px;

  & + .sobre{
    padding-top: 0;
  }

  .breadcrumb{
    margin-bottom: 0;
  }
}

.faq-index {
    padding-top: 32px;
    padding-bottom: 32px;

    @media (min-width: 768px){
        padding-top: 80px;
        padding-bottom: 120px;
    }
}

.section-agenda{
  padding-top: 80px;
  padding-bottom: 120px;

  .title{
    color:  #18191B;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .BTNoverflow{
    overflow: auto;
  }
}

.agenda-nav-tabs{
  border-bottom: 0;
  display: flex;
  gap: 8px;
  width: max-content;

  .nav-item{
    
    .nav-link{
      display: inline-flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 0;
        color: #0068FF;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border: 2px solid transparent;
        transition: 0.4s ease;

        @media(min-width: 992px){
          padding: 16px 24px;
        }

      &.active{
        background:  #0045B5;
        color: white;
        border-color: #0045B5;
        // font-weight: 400;
      }
    }
  }
}


.agenda-content{

  .tab-pane{
    padding-top: 26px;

    .agenda-lista{
      padding: 0;
      margin-bottom: 0;

      .agenda-item{
        display: flex;
        align-items: baseline;
        padding: 16px;
        margin-bottom: 8px;
        border-radius: 10px;
        background-color: #F2F2F3;

        @media(min-width: 992px){
          padding: 16px 24px;
        }

        &:last-child{
          margin-bottom: 0;
        }

        &.item-bg-azul-claro{
          background-color: #EBF2FF;
        }

        &.titulos{
          background-color: white;
          color:#94989E;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          text-transform: uppercase;
          display: none;
          padding: 0;

          @media(min-width: 992px){
            display: flex;
          }
        }

        .texto-hora-titulos{
          width: 192px;
          margin-bottom: 0;
        }
        .texto-hora{
          width: 75px;
          color: #18191B;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; 
          margin-bottom: 0;


          @media(min-width: 992px){
            font-size: 24px;
            width: 192px;
          }
        }

        .textWrapper-titulos {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: max-content;
          margin-bottom: 0;
        }
        .textWrapper{
         width: 100%;
         display: grid;
         row-gap: 16px;
         
         @media(min-width: 992px){
           row-gap: 8px;
         }
         .texto-info{
          width: 100%;
          display: grid;
          grid-template-columns: 1fr;
          grid-auto-rows: max-content;
          color:#18191B;
          font-size: 16px;
          font-style: normal;
          line-height: 120%;
          font-weight: 400;
          margin-bottom: 0;

          @media(min-width: 992px){
            grid-template-columns: 1fr 1fr;
            font-size: 20px;
          }

          &_bloco{
            font-family: 'ipiranga-titulos';
            font-weight: 500;
          }
          &_tema{
            @media(max-width: 992px){
              font-size: 14px;
              margin-top: 8px;
            }
          }
         }
        }
      }
    }
  }
}

.pag-agenda{

  .BTNoverflow{
    overflow: auto;
  }

  .btn.btn-azul-outline{margin-top: 80px;}
}

.como-chegar-interna_sect {
  padding-top: 16px;

  @media(min-width: 992px){
    padding-top: 0;
  }

  .como-chegar_content {
    padding-top: 40px;

    &:first-child {
      padding-top: 0;
    }
  }

  .titulo {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 16px;

    @media(min-width: 992px){
      margin-bottom: 24px;
    }
  }

  .texto {
    color: #000913; 
    font-size: 14px;   
    line-height: normal;

    @media(min-width: 992px){
      font-size: 16px;   
    }

    + .texto {
      margin: 16px 0 0;

      @media(min-width: 992px){
        margin-top: 20px;
      }
    }
  }

  .texto-flex {
    display: flex;
    align-items: center;
    gap: 6px;

    .telefone-vermelho {
      width: 20px;
    }
  }

  .cards_wrapper {
    margin-top: 35px;

    @media(min-width: 992px){
      margin-top: 40px;
    }
  }

  .vai-de-aviao_card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    padding: 22px 12px;
    border-radius: 4px;
    border: 0.5px solid #E4E4E4;
    background: #FFF;

    @media(min-width: 545px){
      height: 106px;
      padding: 22px;
    }

    @media(min-width: 992px){
      border-radius: 7px;
      border-width: 1px;
      height: 180px;
    }

    .gol {     
      @media(max-width: 545px){
        width: 45px;
      }

      @media(max-width: 991px){
        width: 68px;
      }
    }

    .azul {     
      @media(max-width: 545px){
        width: 60px;
      }

      @media(max-width: 991px){
        width: 90px;
      }
    }

    .latam {     
      @media(max-width: 545px){
        width: 73px;
      }

      @media(max-width: 991px){
        width: 111px;
      }
    }

    .voepass {     
      @media(max-width: 991px){
        width: 88px;
      }
    }
  }

  .info_box-navegacao {
    margin: 24px 0 16px;

    @media(min-width: 992px){
      margin: 40px 0 24px;
    }
  }
}

.novo-login {
  
  .titulo-selecione {
    color: #000;
    font-family: 'ipiranga-textos', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 24px;
    margin-top: 16px;

    @media (min-width: 992px) {
      margin-bottom: 16px;
      margin-top: 24px;
      color: #797E86;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
    }
  }

  .radios-mobile {
    margin-bottom: 0;

    .checkmark {
      border-radius: 0;
      border: none;
      padding: 20px 0 16px 24px;
      justify-content: flex-start;
      height: fit-content;
  
      &:hover {
        background-color: #E6ECF8;
        border: none;
      }

      .circulo {
        border-radius: 50%;
        background-color: #CCDAF0;
        width: 40px;
        height: 40px;
        color: #0045B5;
        font-size: 24px;
        font-weight: 800;
        line-height: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
      }

      .titulo-checkmark {
        color: #18191B;
        font-size: 16px;
        font-weight: 500;
        line-height: 13px;
        margin-bottom: 4px;
        text-align: left;
      }

      .sub-checkmark {
        color: #797E86;
        font-size: 12px;
        font-weight: 400;
        line-height: 13px;
        margin-bottom: 0;
      }
    }

    .login_custom-radio {
      margin-top: 0;

      @media (min-width: 992px) {
        width: 100%;
      }

      &:first-of-type {
        .checkmark {
          border-radius: 10px 10px 0 0;
          border: 2px solid #D7D9DB;
          border-bottom: none;

          &:hover {
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
          }
        }
      }

      &:nth-of-type(2) {
        .checkmark {
          border-radius: 0;
          border: 2px solid #D7D9DB;
          border-bottom: none;

          &:hover {
            border-left-color: transparent;
            border-right-color: transparent;
          }
        }
      }

      &:nth-of-type(3) {
        .checkmark {
          border-radius: 0;
          border: 2px solid #D7D9DB;
          border-bottom: none;

          &:hover {
            border-left-color: transparent;
            border-right-color: transparent;
          }
        }
      }

      &:last-of-type {
        .checkmark {
          border-radius: 0 0 10px 10px;
          border: 2px solid #D7D9DB;

          &:hover {
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
          }
        }
      }
    }

  }

  .login_custom-radio_input:checked + .checkmark {
    background-color: #E6ECF8;
  }

  .primeiro-acesso-texto {
    color: #797E86;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 16px;
    margin-top: 24px;
  }

  .intro-perfil {
    margin-top: 8px;
    margin-bottom: 16px;
    color: #18191B;
    font-size: 18px;
    font-weight: 500;
    line-height: 13px;
  }

  .login_custom-radio .checkmark::before, .login_custom-radio .checkmark::after {
    content: none;
  }

  .titulo-senha {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin: 20px 0 16px 0;
  }

  .recuperar-senha {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 16px;
    margin-top: 0;
    line-height: 140%;

    @media (min-width: 992px) {
      font-size: 14px;
    }
  }

  .titulo-selecionado {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 24px;

    @media (min-width: 992px) {
      color: #18191B;
      font-size: 18px;
      font-weight: 500;
      line-height: 13px;
      margin-top: 24px;
    }
  }

  .btn-voltar-desk {
    display: none;

    @media (min-width:992px) {
      display: flex;
    }
   }

}


.galeria_sect {
  display: flex;
  align-items: flex-start;
  
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media(max-width: 767px){
    background-image: none !important;
  }

  @media(min-width: 768px){
    padding-top: 56px;
    height: 810px;
  }

  .galeria_carousel {
    padding-bottom: 55px;

    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      background-color: white;
      opacity: 1;

      &-active {
        background-color: #0045B5;
      }
    }

    &.bullet-azul-claro{

      .swiper-pagination-bullet {
  
        &-active {
          background-color: #00ADFF;
        }
      }
    }
  }

  .title {
    color: #000;
    font-family: "ipiranga-titulos", sans-serif;
    font-size: 48px;  
    line-height: normal;
    margin-bottom: 24px;
  }  
}

.tabs_overflow{
  overflow: auto;
  margin-bottom: 24px;
} 

.tabs_overflow-scroll{
  overflow: auto;
  margin: 0 -24px;
  padding: 4px 24px;   

  &::-webkit-scrollbar {
    display: none;
  }
}

.galeria-nav-tabs {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 16px;
  border: 0;  
  margin-bottom: 20px;

  @media(min-width: 768px){
    margin-bottom: 28px;
  }

  .nav-link {
    border-radius: 6px;
    padding: 8px 24px;    
    border: 2px solid #0045B5;
    color: #0045B5; 
    font-size: 12px; 
    font-weight: 400;
    line-height: 140%;
    white-space: nowrap;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 58px;
    margin: 0;

    @media(min-width: 768px){     
      padding: 10px 24px;  
      border-radius: 8px;
      border: 2px solid #000;
      color: black;

      &:hover {
        border-color: #000;
      }
    }

    &_data{
      font-size: 16px;
    }

    &.active {
      border-color: #0045B5;
      background-color: #0045B5;
      color: white;
    }
  }

  &.interna {
    .nav-item {
      @media (max-width: 992px) {
        &:last-of-type {
          padding-right: 16px;
        }
      }
    }

    .nav-link {
      @media (max-width: 992px) {
        height: 48px;
      }
      @media(min-width: 768px){        
        border: 2px solid #0045B5;
        color: #0045B5;
  
        &:hover {
          border-color: #0045B5;
        }
      }

      &.active {      
        color: white;
      }
    }
    
  }
}

.galeria_card {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  // height: 100%;
  border-radius: 16px;
  background-color: #FFF;
  text-decoration: none;
  border: 1px solid #E9EAEA; 

  @media(min-width: 768px){
    border: 0;
    border-radius: 8px;
  }

  .icon {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.4s ease;
  }

  &:hover {
    .image_holder {
      .image {
        transform: scale(1.1);
      }
    }
  }
  
  .image_holder {
    position: relative;
    width: 100%;

    &::after {
      content: '';
      display: block;
      padding-bottom: 88%;

      @media(min-width: 1400px){
        padding-bottom: 75%;
      }
    }

    .image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      transition: 0.4s ease;

      &.image-vertical{
        background-size: contain;
        background-color: black;
      }
    }
  }

  .content {  
    display: flex;
    flex-direction: column-reverse;  
    gap: 8px;  
    padding: 16px 16px 24px;   
    

    @media(min-width: 768px){
      position: absolute;
      z-index: 3;
      left: 16px;
      right: 16px;
      bottom: 16px;

      padding: 0;
      border: 0;

      flex-direction: column;
    }

    .date {
      color: #939598;     
      font-size: 12px;    
      line-height: normal;

      @media(min-width: 768px){
        color: white;
      }
    }

    .desc {
      overflow: hidden; 
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; 
      -webkit-box-orient: vertical;

      color: #18191B;
      font-family: "ipiranga-titulos", sans-serif;
      font-size: 16px;    
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0;

      @media(min-width: 768px){
        color: white;   
        font-weight: 500;
        line-height: 120%; 
      }
    }
  }

  .overlay {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: black;
    mix-blend-mode: multiply;
    transition: 0.4s ease;
    border-radius: inherit;

    @media(min-width: 768px){
      display: block;      
      height: 60%;
      background: linear-gradient(180deg, rgba(4, 4, 4, 0.00) 0%, rgba(4, 4, 4, 0.00) 0.01%, rgba(4, 4, 4, 0.72) 57.29%);
    }
  }
  
  &_legenda{
    margin-top: 8px;
    margin-bottom: 0;
    font-size: 14px;
    color: black;
  }

  &.video {

    .overlay {
      display: block;
      opacity: 0.3;

      @media(min-width: 768px){
        background-color: black;
        height: 100%;
      }
    }  

    .icon {
      @media(max-width: 991px){
        width: 48px;
      }
    }
    
    &:hover {
      .overlay {
        opacity: 0.4;
      }

      .icon {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }

  &.image {

    .icon {
      opacity: 0;
    }

    .overlay{
      opacity: 0;

      @media(min-width: 768px){
        background-color: black;
        height: 100%;
      }
    }

    &:hover {
      .icon {
        opacity: 1;
      }  
      
      .overlay {
        opacity: 0.4;
      }
    }
  }
}

.min-height_controller {
  @media(min-width: 768px){
    min-height: calc(100vh - 351px);
  }

  @media(min-width: 992px){
    min-height: calc(100vh - 236px);
  }
}

.form-intro {
  color: #353535;
  font-size: 14px;
  line-height: normal;
  
  & + .form-intro {
    margin: 16px 0 0;
  }  
}

.regulamento {
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;

  .titulo {
    color: #0045B5;
    font-size: 23px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
  }

  &-link {
    color: #0045B5;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    margin-bottom: 64px;
    transition: all ease .3s;

    &:hover {
      text-decoration: none;
    }
  }

  .btn {
    width: 100%;

    @media (min-width: 992px) {
      max-width: 168px;
    }
  }
}

.sorteio_banner {
  position: relative;
  width: 100%;
  height: 149px;
  margin-bottom: 41px;

  @media(min-width: 992px){
    height: 220px;
    margin-top: 32px;
  }

  .content_holder {
    display: flex;
    align-items: center;
    padding: 0 24px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #0068FF;
    
    border-radius: 10px;

    @media(min-width: 992px){
      border-radius: 20px;
      padding: 0 48px;
    }

    @media(min-width: 1200px){   
      padding: 0 56px;
    }

    .content {
      position: relative;
      z-index: 2;
    }
    
    .banner_title {
      color: #FFF;
      font-family: "ipiranga-titulos", sans-serif;
      font-size: 24px;    
      font-weight: 700;
      line-height: normal;
      margin-bottom: 8px;

      @media(min-width: 992px){
        font-size: 48px;
        margin-bottom: 24px;
        line-height: 1;
      }
    }

    .btn {
      height: 33px;
      font-size: 14px;
      padding: 0 16px;

      @media(min-width: 992px){
        height: 51px;
        font-size: 16px;
      }
    }
  }

  .posto_image {
    position: absolute;
    right: 0;
    bottom: 0;

    @media(min-width: 992px){
      right: -30px;  
    }
  }
}

.roteiro_sect {
  overflow: hidden;
  padding: 60px 0 32px;

  @media(min-width: 992px){
    padding: 60px 0 80px;
  }

  .titulo {
    margin-bottom: 16px;
  }

  .text {
    color: #303236;
    font-size: 16px;   
    line-height: normal;

    margin-bottom: 32px;
  }

  .roteiro_card {    
    position: relative;
    width: 262px;
    height: 364px;  

    color: white; 
    font-family: "ipiranga-titulos", sans-serif;   

    .front, .back {
      border-radius: 20px;
    }

    .front {    
      position: relative;  
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; 

      padding: 12px 21px;

      &::before {
        content: '';
        display: block;

        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 180px;
        background: linear-gradient(180deg, #0045B5 0%, rgba(0, 69, 181, 0.00) 100%);
      
        border-radius: 20px 20px 0 0;
      }

      .front_content {
        position: relative;
        z-index: 2;
      }

      .main-date {     
        font-size: 48px;  
        font-weight: 700;
        line-height: normal;        
      }

      .local {       
        font-size: 24px;   
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
    }

    .back {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #0045B5;

      padding: 24px;

      .local {   
        font-size: 32px;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 8px; 
      }

      .date {    
        font-size: 20px;  
        font-weight: 500;
        line-height: 140%;

        margin-bottom: 16px;
      }

      .desc {    
        font-size: 16px;     
        line-height: normal;
        margin-bottom: 0;
      }

      .card_link {
        display: inline-flex;
        width: fit-content;

        color: #FFD100;   
        font-size: 16px;   
        line-height: normal;

        transition: 0.3s ease;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .roteiro_carousel {
    overflow: visible;

    .bottom_holder {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-top: 24px;

      .interact_wrapper {
        display: none;

        @media(min-width: 992px){
          display: flex;
          align-items: center;
          gap: 8px;

          width: fit-content;
          padding: 10px 16px;
          border-radius: 10px;
          background-color: #F2F2F3;
        }

        .text {
          color: #303236;
          font-size: 14px; 
          line-height: normal;
          margin-bottom: 0;
        }
      }
    }

    .btns_wrapper {
      display: flex;
      align-items: center;
      gap: 16px; 

      .swiper-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        outline: none;
        border: 0;
        position: relative;
        bottom: initial;
        top: initial;
        right: initial;
        left: initial;
        margin-top: 0;
        width: 52px;
        min-width: 52px;
        height: 52px;
        border-radius: 50%;
        background-color: #0045B5;

        &-disabled {
          background-color: #0045B5;     
          opacity: 0.5;         
        }

        &::after {
          display: none;
        }
      }
    }

    .swiper-slide {
      position: relative;
      width: 262px;

      .virar_btn {
        position: absolute;
        z-index: 9;
        right: -16px;
        top: -16px;
  
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
    
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: #FFD100;
  
        @media(min-width: 992px){
          display: none;
        }
    
        .virar_text {
          color: #000;
          font-family: "ipiranga-titulos", sans-serif;
          font-size: 12px;    
          font-weight: 500;
          line-height: normal;
          margin-bottom: 0;
        }      
      }
    }
  }  
}

.banner-informativo {
  width: 100%;
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 26px 24px 24px;
  border-radius: 10px;
  margin-bottom: 32px;

  @media(min-width: 992px){
    padding: 35px 52px;
    border-radius: 20px;

    margin-bottom: 48px;
  }

  &.guia-viagem {
    background-image: url('../img/img-clube-milhao-banner-guia-mobile.jpg');

    @media(min-width: 992px){
      background-image: url('../img/img-clube-milhao-banner-guia.jpg');
    }
  }

  &_content {
    color: #FFF;
    font-family: "ipiranga-titulos", sans-serif;

    @media(min-width: 992px){
      max-width: 423px;
    }

    &_title {
      font-size: 24px;
      line-height: normal;
      margin-bottom: 8px;

      @media(min-width: 992px){ 
        font-size: 32px;     
        font-weight: 500;      
      }
    }

    &_text {
      font-size: 14px;     
      line-height: normal;
      margin-bottom: 24px;

      @media(min-width: 992px){       
        font-size: 16px;    
      }
    }

    .btn {
      width: 100%;
      height: 43px;
      font-size: 14px;

      @media(min-width: 992px){
        width: 235px;
      }
    }
  }
}

.video-sect {
  padding: 80px 0 20px;

  @media(min-width: 992px){
    padding: 80px 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .titulo {
      max-width: 934px;
      margin: 0 0 40px;
      text-align: center;    
      color: #001C48;

      @media (max-width: 768px) {
       font-size: 22px;
       margin-bottom: 24px;
      }
  
      @media(min-width: 992px){
        font-size: 48px;
      } 

      .w-500 {
        font-weight: 500;
      }
    }

    .videoWrapper{
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      position: relative;
      background-size: cover;
      background-position: center;
      border-radius: 8px;
  
      @media(min-width: 992px){
        max-width: 742px;
      }
  
      .btn-play{
        width: 40px;
        display: block;
        position: absolute;
        top: calc((100% / 2) - 20px);
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        cursor: pointer;
  
        @media(min-width: 992px){
          width: 92px;
          top: calc((100% / 2) - 46px);
        }
  
        &:hover{
          opacity: .7;
        }
      }
  
      .video{
        width: 100%;
        border-radius: 8px;

        
      }
      
    }
  }

  

  .video {
    width: 100%;
    max-width: 743px;
    aspect-ratio: 16/9;   
  }
}

.video-margin {
  
  @media (max-width: 768px) {
    padding: 48px 0;
  }

}

.video-padding{
  padding: 60px 0 30px;

  @media (min-width: 992px) {
    padding: 80px 0 37px;
  }

  .container {
    .titulo {
      max-width: 100%;
    }
  }
}

video{
  width: 100% !important;
}

.nav-tabs-wrapper-galeria{
  border-bottom: 0;
  margin-bottom: 16px;
  align-items: baseline;

  @media (min-width: 992px) {
    margin-bottom: 24px;
  }
  
   .nav-item{
    width: 50%;

    @media (min-width: 768px) {
      width: inherit;
    }

     .nav-link{
      color: #939598;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 12px 0;
      border: none;
      border-bottom: 2px solid #D1D3D4;
      transition: all ease .3s;
      width: 100%;

      @media (min-width: 768px) {
        padding: 12px 11px;
        min-width: 170px;
      }

      &:hover {
        color: #0045b5;
        border-bottom: 2px solid #0045B5;
      }

      &.active{
        color:  #0045B5;
        border-bottom: 2px solid #0045B5;
      }
     }
   }
}
.margin-video {
  padding: 60px 0 80px 0;

  @media (min-width: 992px) {
    padding: 20px 0 110px 0;
  }
}

.guia-viagem_carousel {
  .banner-informativo {
    margin-bottom: 60px;
    min-height: 198px;
    max-height: 198px;

    @media (min-width: 992px) {
      margin-bottom: 76px;
      min-height: 242px;
    }

    &.revista {
      .banner-informativo_content_title {
        margin-bottom: 24px;
      }
    }
  }

  .autoplay-wrapper {
    width: 100%;
    height: 198px;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    @media (min-width: 992px) {
      height: 242px;
      border-radius: 20px;
    }
  }

  .autoplay-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;
    z-index: 11;
  }

  .bar-progress {
    --progress: 0;
    width: calc(var(--progress) * 100%);
    height: 100%;
    background-color: #FFB206;
    transition: width 0.1s linear;
    transform-origin: left;
  }

  .swiper-button-next {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    color: #0045B5;
    font-size: 15px;
    transition: 0.3s ease;
    cursor: pointer;
    border: none;
    background-color: transparent;
    right: calc(50% - 92px);
    bottom: 36px;
    top: inherit;
    transform: translateX(-50%);
    padding: 0;
    height: inherit;

    &:focus {
      outline: none;
      border: none;
    }

    &:hover {
      opacity: 0.8;
    }
    &::after{display: none;}

    &.swiper-button-disabled{
      background-color: #E9EAEA;
      opacity: 1;
    }

    @media (min-width: 992px) {
      display: flex;
    }
  }

  .swiper-button-prev {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    color: #0045B5;
    font-size: 15px;
    transition: 0.3s ease;
    cursor: pointer;
    background-color: transparent;
    border: none;
    left: calc(50% - 92px);
    bottom: 36px;
    top: inherit;
    transform: translateX(50%);
    padding: 0;
    height: inherit;

    &:focus {
      outline: none;
      border: none;
    }

    &:hover {
      opacity: 0.8;
    }
    &::after{display: none;}

    &.swiper-button-disabled{
      background-color: #E9EAEA;
      opacity: 1;
    }

    @media (min-width: 992px) {
      display: flex;
    }
  }

  .swiper-pagination{
     display: flex;
     justify-content: center;
     align-items: center;
     bottom: 32px !important;
     color: #0045B5;
     z-index: -1;

     @media (min-width: 992px) {
      bottom: 42px !important;
     }

     .swiper-pagination-bullet {
      width: 16px;
      height: 12px;
      background-color: #D4D5D6;
      opacity: .6;
      border-radius: 10px;

      &-active {
        background-color: #0045B5;
        width: 30px;
        opacity: 1;
      }
    }
  }
}

.link-ver-todos{
  color: #0045B5;
  font-family: "ipiranga-titulos", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.filtrosOverflow{
  overflow: hidden;
}

.carousel-filtros{
  overflow: visible;
  margin-bottom: 24px;

  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide{
    width: max-content;

    .btn{
      height: auto;
      padding: 16px 24px;
    }
  }
}

.container-desktop{
    @media(max-width: 767px){
        padding-left: 0;
        padding-right: 0;
        max-width: 100% !important;
    }
}

.ajuda_sect {
  padding-bottom: 80px;
  position: relative;
  background-color: white;
  padding-top: 24px;
  border-radius: 20px 20px 0 0;
  min-height: calc(100vh - 302px);
  display: flex;

  @media (min-width: 409px) {
    min-height: calc(100vh - 308px);
  }

  @media (min-width: 515px) {
    min-height: calc(100vh - 284px);
  }

  @media (min-width: 992px){
    min-height: 500px;
    padding-top: 0;
    overflow: hidden;
    background-color: transparent;
    border-radius: 0;
    min-height: calc(100vh - 164px);
  }

  .btn-voltar {
    @media (min-width: 992px) {
      margin-top: 32px;
    }
  }

  .titulo {
    color: #000;
    font-family: "ipiranga-titulos";
    font-size: 24px;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 8px;
    margin-top: 16px;

    @media (min-width: 992px) {
      margin-bottom: 16px;
      font-size: 32px;
      line-height: normal;
      margin-top: 24px;
    }
  }

  .texto {
    color: #797E86;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;

    @media (min-width: 992px) {
      font-size: 18px;
    }
  }

  .row {
    align-items: flex-start;
    margin-top: 32px;

    @media (min-width: 992px) {
      margin-top: 40px;
    }
  }

  .form-label {
    line-height: 14px;
    margin-bottom: 4px;
  }

  .form-select {
    height: 48px;
    padding: 5px 24px;
  }

  textarea {
    min-height: 110px;
  }

  .group {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: 992px) {
      flex-direction: row;
      gap: 24px;
    }

    .group-area {
      @media (min-width: 992px) {
        width: calc(50% - 12px);
      }
    }
  }

  .btn-azul {
    margin-top: 32px;
    width: 100%;
    height: 56px;

    @media (min-width: 992px) {
      margin-top: 48px;
    }
  }

  .sucesso {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 58px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .titulo {
      color: #0045B5;
      text-align: center;
      font-family: "ipiranga-titulos";
      font-size: 32px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 16px;

      @media (min-width: 992px) {
        font-size: 48px;
      }
    }

    .texto {
      color: #303236;
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0;
      text-align: center;
    }

    .c-azul {
      color: #0045B5;
      font-weight: 600;
    }
  }
}

.ancora-topo {
  display: none;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  right: 24px;
  bottom: 8%;
  text-decoration: none;
  transition: opacity 0.5s ease;

  &:hover {
    .circulo {
      background-color: darken(#EBF2FF, 10%);
    }
  }

  .circulo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    margin-bottom: 4px;
    background-color: #EBF2FF;
    border-radius: 50%;
  }

  .texto {
    color: #0045B5;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
  }
}

.info-cadastro-area {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 32px;
  gap: 24px;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }

  &_grupo {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
    max-width: 455px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .texto {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0;
    }
  }
}

.direitos {
  border-radius: 6px;
  background-color: #E6ECF8;
  width: 100%;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  @media (min-width: 992px) {
    margin-bottom: 16px;
  }

  .texto {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
    margin-left: 8px;
  }

  .saiba-mais {
    color: #0045B5;
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 0;
    cursor: pointer;
    transition: all ease .3s;

    &:hover {
      color: darken(#0045B5, 10%);
    }
  }
}

.agenda_sect {
  padding: 58px 0 80px; 

  @media (min-width: 992px) {
    padding: 24px 0 80px;
  }

  .BTNoverflow{
    overflow: auto;
  }

  .novidades_title {
    margin-bottom: 16px;
  }

  .texto {
    margin-bottom: 24px;
  }
}

.agenda-sect {
    overflow: hidden;
    padding: 16px 0 40px;

    @media(min-width: 1200px){
        padding: 24px 0 70px;
    }

    &_title {
        display: none;
        color: #000;  
        font-size: 40px;  
        font-weight: 500;
        margin-bottom: 16px;
        font-family: 'ipiranga-titulos', sans-serif;

        @media(min-width: 1200px){
        display: block;      
        }
    }

    &_subtitle {
        color: #303236;
        font-family: 'ipiranga-titulos', sans-serif;
        font-size: 16px;  
        font-weight: 700;
        margin-bottom: 8px; 

        @media(min-width: 1200px){
        font-size: 20px;      
        }
    }

    &_text {
        color: #303236;
        font-family: 'ipiranga-titulos', sans-serif;
        font-size: 16px;
        margin-bottom: 24px;

        @media(min-width: 1200px){
        font-size: 18px;
        margin-bottom: 40px;    
        }
    }

    .date-control {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        gap: 24px;

        width: 100%;

        margin-bottom: 16px;

        @media(min-width: 768px){
        width: initial;
        flex-direction: row;
        justify-content: flex-start;
        gap: 24px;
        }

        @media(min-width: 1200px){
        margin-bottom: 24px;
        }

        &_btns {
        display: flex;
        align-items: center;
        gap: 16px;

        .swiper-agenda-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;

            font-size: 18px;

            color: #2D3648;
            background-color: transparent;
            border: 0;
            outline: none;

            transition: 0.3s ease;

            &:hover {
            opacity: 0.8;
            }
        }

        .swiper-button-disabled {
            opacity: 0.1;

            &:hover {
            opacity: 0.1;
            }
        }
        }

        &_values {
        &_value {
            color: #000;
            font-family: "ipiranga-titulos", sans-serif;
            font-size: 20px;
            font-weight: 500;
            line-height: normal;

            @media(min-width: 1200px){
            font-size: 32px;
            }
        }
        }
    }

    .role-para-o-lado {    
        display: flex;
        align-items: center;
        width: fit-content;
        gap: 16px;

        margin: 24px 0 0 auto;

        &_text {
        color: #0045B5;
        font-family: 'ipiranga-titulos', sans-serif;
        font-size: 16px;
        font-style: italic;
        line-height: 130%; 
        margin-bottom: 0;
        }
    }

    .agenda {
        background-color: white;
        
        @media(min-width: 1200px){
        display: flex;
        align-items: flex-end;
        }

        &_arenas {
        display: flex;
        gap: 16px;
        flex-wrap: nowrap;
        padding: 0 24px 6px;

        overflow: auto;
        border-bottom: 1px solid #EBF2FF;
        margin-bottom: 18px;

        @media(min-width: 1200px){
            flex-direction: column;
            gap: 0;
            overflow: initial;
            border: 0;
            margin-bottom: 0;
            padding: 0 0 10px;
        }

        &_button {
            width: fit-content;     

            background-color: transparent;
            outline: none;
            border: 0;   
            padding: 0;
            
            @media(min-width: 1200px){
            cursor: default;
            border: 1px solid #F2F2F3;
            border-right: 0;
            width: 281px;
            height: 131px;

            padding: 0 40px;
        
            &.agenda_arenas_button--amarela {
                .agenda_arenas_button_text {
                border-color: #FFD100;
                }           
            }

            &.agenda_arenas_button--azul {
                .agenda_arenas_button_text {
                border-color: #0045B5;
                }     
            }

            &.agenda_arenas_button--laranja {
                .agenda_arenas_button_text {
                border-color: #FF6900;
                }            
            }

            & + .agenda_arenas_button {
                border-top: 0;
            }
            }

            &_text {
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            color: #0045B5;
            font-family: 'ipiranga-titulos', sans-serif;
            font-size: 16px;    
            font-weight: 500;
            line-height: normal;
            border-bottom: 4px solid transparent;
            padding: 12px 0 8px;

              @media(min-width: 1200px){
                  width: fit-content;
                  font-size: 24px;  
                  padding: 0 0 4px;

                  color: #000000;
              }

              .number{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 16px;
                height: 16px;
                border-radius: 22px;
                background: #94DE00;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: white;
                margin-left: 4px; 
                
                @media(min-width: 1200px){
                  display: none;
                }
               
              }
            }
            
            &.active {        
            .agenda_arenas_button_text {
                color: #000000;
            }

            &.agenda_arenas_button--amarela {
                .agenda_arenas_button_text {
                border-color: #FFD100;
                }           
            }

            &.agenda_arenas_button--azul {
                .agenda_arenas_button_text {
                border-color: #0045B5;
                }     
            }

            &.agenda_arenas_button--laranja {
                .agenda_arenas_button_text {
                border-color: #FF6900;
                }            
            }
            }
        }
        }

        &_wrapper {      
        padding: 0 16px;

        @media(min-width: 1200px){
            position: relative;
            overflow-x: auto;      
            width: fit-content;
            padding: 0;
            border-left: 1px solid #F2F2F3;          

            &::-webkit-scrollbar {   
            height: 10px;               
            }
            
            &::-webkit-scrollbar-track {
            background-color: #F4F4F5;    
            }
            
            &::-webkit-scrollbar-thumb {       
            background: #D1D3D4;        
            border-radius: 0;    
            }
        }

        &_hours {
            @media(min-width: 1200px){
            display: flex;
            align-items: center;
            width: 100%;
            padding: 11px 32px;         
            }

            &_hour {
            position: relative;
            width: 357px;
            min-width: 357px;
            max-width: 357px;

            &:last-child {
                &::after {
                display: none;
                }

                width: 80px;
                min-width: 80px;
                max-width: 80px;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: -32px;
                right: 0;
                bottom: -12px;
                height: 1px;
                background-color: #F2F2F3;
            } 
            }
        }

        &_block {
            display: flex;
            align-items: flex-start;
            gap: 5px;

            @media(min-width: 1200px){
            height: 131px;
            align-items: center;          
            padding: 0 32px;            
            }

            &_hours {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            
            &_hour {
                position: relative;
                    height: 204px;

                &:last-child {
                    height: 60px;
                }

                &::after {
                content: '';
                display: block;
                position: absolute;
                top: 12px;
                left: 48px;
                width: calc(100vw - 80px);
                height: 1px;
                background-color: #EBF2FF;
                }

                &_value {
                color: #939598;
                font-family: 'ipiranga-titulos', sans-serif;
                font-size: 14px;             
                font-weight: 500;           
                }
            }
            }

            .events-wrapper {
            width: calc(100% - 45px);
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            gap: 4px;
            
            padding-top: 21px;

            @media(min-width: 1200px){
                width: 100%;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                padding: 0;
            }
            }

            &_event {
            cursor: pointer;
            position: relative;
            width: 100%;      
            padding: 16px;

            border-radius: 6px;
            background-color: #E6ECF8;

            @media(min-width: 1200px){
                width: initial;
                height: 99px;

                transition: 0.3s ease;

                &:hover {
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                opacity: 0.9;
                }

                &::after {
                content: '';
                display: block;
                position: absolute;
                left: -32px;
                bottom: -16px;
                right: 0;
                height: 1px;
                background-color: #F2F2F3;
                }
            }

            &.disabled {           
                background-color: #F4F4F5;

                .agenda_wrapper_block_event_title, .agenda_wrapper_block_event_desc, .agenda_wrapper_block_event_cta {
                color: #D1D3D4;
                }           

                &.confirmado {
                background-color: #F2FBE9;

                .agenda_wrapper_block_event_title, .agenda_wrapper_block_event_desc, .agenda_wrapper_block_event_cta {
                    color: #D1D3D4;
                }        
                
                .agenda_wrapper_block_event_cta {              
                    color: #BBD686;
                }
                }
            }

            &.confirmado {
                background-color: #CBEFA6;                  
                
                .agenda_wrapper_block_event_cta {
                color: #00551D;
                }
            }

            &_tag {
                position: absolute;
                right: 0;
                top: -6px;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                padding: 0 10px;
                height: 19px;
                border-radius: 4px 4px 0px 4px;
                    
                font-size: 10px;       
                font-weight: 500;
                line-height: 110%; 
                text-transform: uppercase;

                &.confirmado {
                background-color: #94DE00;
                color: #000;    
                }

                &.ultimas-vagas {
                background-color: #FFD100;
                color: #000;    
                }

                &.esgotado {
                background-color: #939598;
                color: white;    
                }
            }

            &.no-desc {
                .agenda_wrapper_block_event_desc {
                display: none;
                }
            }

            &.empty {
                pointer-events: none;
                background-color: #FAFAFA;
            }

            .agenda_wrapper_block_event_title,
            .agenda_wrapper_block_event_desc {
                white-space: nowrap;      
                overflow: hidden;         
                text-overflow: ellipsis;   
                display: block;           
                width: 100%;               
            }

            &_title {         
                color: #000;      
                font-family: 'ipiranga-titulos', sans-serif;
                font-size: 16px;     
                font-weight: 500;
                line-height: normal;
                margin-bottom: 4px;
            }

            &_desc {         
                color: #000;     
                font-size: 14px;         
                line-height: normal;
                margin-bottom: 0;
            }

            &_cta {
                position: absolute;
                right: 16px;
                bottom: 8px;

                color: #0045B5;            
                font-family: 'ipiranga-titulos', sans-serif;
                font-size: 14px;     
                line-height: normal;

                text-decoration: none;
            }
            }
        }
        }

        &_tempo-atual{
            position: absolute;
            top: 50px;
            left: 0;
            z-index: 10;
            display: flex;
            align-items: center;
            width: 100%;
            transition: top ease 0.1s;

            @media (min-width: 1200px){
                top: 0;
                flex-direction: column;
                width: initial;
                height: 100%;
                transition: left ease 0.1s;
            }

            &_valor{
                display: block;
                margin-bottom: 0;
                padding: 5px 8px;
                background-color: #F04C25;
                color: #FFF;
                font-family: 'ipiranga-titulos';
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                border-radius: 5px;
            }

            &::after{
                content: '';
                display: block;
                width: 100%;
                height: 3px;
                background-color: #F04C25;

                @media (min-width: 1200px){
                    width: 3px;
                    height: 100%;
                }
            }
        }
    }
}

.info-sucesso{
 margin-top: 64px;
 margin-bottom: 40px;

 .titulo.xl-alt{line-height: normal;}

 .paragrafo{
  color:  #303236;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  }
}
.mb-74{
  margin-bottom: 74px;
}

.z-index-1{
    z-index: 1;
}

.section-chamada-sorteio{
  margin-bottom: 32px;

  .titulo{
    color:  #18191B;
    font-family: "ipiranga-titulos", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;

    @media(min-width: 992px){
      font-size: 48px;
    }
  }
  .titulo-destaque{
    color: #0045B5;
    font-family: "ipiranga-titulos", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 16px;
  }
  .paragrafo{
    color: #303236;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;

    a{
        color: #0045B5;
        text-decoration-line: underline;
        transition: all ease 0.3s;

        &:hover{
          opacity: 0.8;
          text-decoration-line: none;
        }
    }
  }
}

.section-chamada-mapa{
  margin-bottom: 32px;

  @media(min-width: 992px){
    margin-bottom: 80px;
  }

  .titulo{
    color:  #18191B;
    font-family: "ipiranga-titulos", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;

    @media(min-width: 992px){
      font-size: 48px;
    }
  }
  .titulo-destaque{
    color: #0045B5;
    font-family: "ipiranga-titulos", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 16px;
  }
  .paragrafo{
    color: #303236;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;

    a{
        color: #0045B5;
        text-decoration-line: underline;
        transition: all ease 0.3s;

        &:hover{
          opacity: 0.8;
          text-decoration-line: none;
        }
    }
  }
  .link-externo{
    color:  #0045B5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    display: flex;
    gap: 16px;
    transition: all ease 0.3s;

    &:hover{
      opacity: 0.8;

      .text-decoration-underline{
        text-decoration: none !important;
      }
    }

    .icones.icone-seta-esquerda{
           transform: rotate(180deg);
           font-size: 24px;
    }
  }
}