.alert {
    display: flex;
    align-items: center;
    border-radius: 6px;
    gap: 10px;
    padding: 9px 24px;

    .text {           
        font-size: 14px;
        line-height: 1;
        margin-bottom: 0;
    }

    &-danger {
        background-color: #F04C25;
        color: #FFF; 
    }
}

.alerta-inscricao {
    border-radius: 6px;
    background: #FFF7D1;
    display: flex;
    padding: 8px 44px 8px 10px;
    align-items: center;
    gap: 10px;
    color: #000;
    border: none;
    margin-top: 24px;
  
    .texto-alerta {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 0;
    }
    
    .close {
        padding: 0;
        outline: none;
        border: none;
        margin: 0;
        background-color: transparent;
        color: black;
        position: absolute;
        right: 10px;
        transition: all ease .3s;

        &:hover {
            opacity: .7;
        }
    }

    &-azul {
        background-color: #E6ECF8;
    }
}