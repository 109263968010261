/* ==========================================================================
   Forms
   ========================================================================== */
.form-label {
   color: #353535;
   font-size: 14px;
   font-weight: 400;
   line-height: 38px; 
   margin-bottom: 0;
}

.form_holder-with-icon {
   position: relative;

   .form-control {
      padding-right: 50px;
   }

   .icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
   }
}

.form-control {
   height: 54px;
   border-radius: 6px;
   border: 1px solid #BDBDBD;
   background: #FFF;
   color: #495057;
   font-size: 14px;
   line-height: 38px;
   padding: 8px 23px 8px 23px;;

   &:disabled,
   &:read-only {
      background-color: #F2F2F3;
   }

   &::-webkit-input-placeholder {
      color: #AAA;
   }

   &:-moz-placeholder {
      color: #AAA;
   }

   &::-moz-placeholder {
      color: #AAA;
   }

   &:-ms-input-placeholder {
      color: #AAA;
   }
}

.form-select {
   padding: 16px;
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   background-size: 16px;
   height: 54px;
   border: 1px solid #BDBDBD;

   &:disabled{
      background-color: #F2F2F3;
   }   
}

.select2_holder {
   position: relative;

   .icone-chevron-down {
      position: absolute;
      z-index: 2;
      right: 8px;
      top: 50%;
      font-size: 24px;
      transform: translateY(-50%);
      pointer-events: none;
   }
}

.select2 {
   width: 100% !important;
}

.select2-selection {
   &.select2-selection--single {
      height: 54px !important;

      .select2-selection__rendered {
         display: flex !important;
         align-items: center;
         height: 100%;
         font-size: 14px;
         color: #495057 !important;
      }
   }
} 

.select2-selection__placeholder {
   color: #AAAAAA !important;
}

.select2-selection__arrow {
   display: none !important;
}

.form-check {
   display: flex;
   align-items: center;

   .form-check-input {
      height: 26px;
      width: 26px;
      margin-top: 0;
   }

   .form-check-input:checked {
      background-color: #0045B5;
      border-color: #0045B5;
   }

   .form-check-label {
      color: #58595B;
      font-size: 18px;
      font-weight: 400;
      line-height: 140%;
      margin-left: 10px;

      small{
         display: block;
         margin-top: 24px;
         font-size: 12px;
      }
   }

   &.checkbox-align-start{
      align-items: flex-start;
   }

   &.checkbox-align-middle{
      height: 54px;
   }

   &.error{
      position: relative;

      .form-check-input{
         border-color: #F04C25;
      }

      .error_text{
         position: absolute;
         left: 36px;
         bottom: -2px;
         font-size: 12px;
         color: #F04C25;
         transform: translateY(100%);
      }
   }
}

.form_holder { 

   &.error {
      .form-control,
      .form-select {
         border-color: #F04C25;
      }

      .error_text {
         display: block;
      }
   }

   .error_text {
      display: none;
      color: #F04C25; 
      font-size: 12px;    
      line-height: normal;
      margin: 6px 0 0;
   }
}

.mt-label{

   @media (min-width: 768px){
      margin-top: 38px;
   }
}

.input-anexar-img {
   margin-top: 16px;
   
   input::file-selector-button {
      display: none;
   }

   .form-control {
      background-color: white;
   }

   .form_holder-with-icon {
      .icon {
         background-color: #E6ECF8;
         width: 129px;
         height: 52px;
         right: 1px;
         border-radius:  0px 5px 5px 0px;
         display: flex;
         align-items: center;
         justify-content: center;
         transition: all ease;
      }

      .text {
         color: #0045B5;
         font-size: 16px;
         font-style: normal;
         font-weight: 500;
         line-height: 20px;
         display: flex;
      }

      .icone-anexar {
         margin-right: 10px;
      }

      &:hover {
         .icon {
            background-color: darken( #E6ECF8, 10%);
         }
      }
   }

   .texto-info {
      margin-bottom: 0;
      color: #7A8598;
      text-align: right;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      margin-top: 4px;
   }
}

.custom-radio-simple {
   display: flex;
   align-items: center;
   position: relative;

   .checkmark {
      position: relative;    
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #58595B;
      margin-right: 8px;

      &::before {           
         content: '';
         display: block;
         width: 10px;
         height: 10px;
         border-radius: 50%;
         background-color: #0045B5;
         opacity: 0;
         transition: 0.3s ease;
      }
   }  

   input {
      opacity: 0;
      width: 0;
      overflow: hidden;      

      &:checked {
         ~ .input-text {
            color: #0045B5;
         }

         ~ .checkmark {
            border-color: #0045B5;

            &::before {
              opacity: 1;
            }
         }
      }
   }

   .input-text {
      color: #58595B;    
      font-size: 14px;  
      line-height: 13px; 
   }
}