
 @keyframes fade-in {
   0%{
     opacity: 0;
   }
    100% {
       opacity: 1;
    }
 }

 @keyframes slide-in {
    to {
       transform: translate(0, 0);
    }
 }

 @keyframes scale-up {
   to {
      transform: scale(1);
   }
 }

 @keyframes slide-in-centered {
   to {
      transform: translate(-50%, 0)
   }
 }

 @keyframes donuts-fade-1 {
   0%{
     top: 20px;
     opacity: 0;
   }
   50%{
     top: 20px;
     opacity: 0;
   }
   100% {
     top: 0;
     opacity: 1;
   }
 }

 @keyframes donuts-fade-2 {
   0%{
     bottom: 20px;
     opacity: 0;
   }
   50%{
     bottom: 20px;
     opacity: 0;
   }
   100% {
     bottom: 0;
     opacity: 1;
   }
 }